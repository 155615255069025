/**
 * 
 * ควรตั้งชื่อ name ถ้าเป็นหน้าตาราง ให้เป็นชื่อเดี่ยวๆ ไม่ต้องใส่ -lists
 * ถ้าเป็นหน้าอื่นๆ ตามด้วย ขีด - และชื่อหน้านั้นๆ เช่น -detail -form -edit -add
 * 
 * ตัวอย่าง หน้า user-lists ให้เป็น user
 * หน้า user-lists-add ให้เป็น user-lists-add
 * หน้า user-lists-detail ให้เป็น user-lists-detail
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import {
  isUserLoggedIn,
  isUserGetPermission,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import erp from './routes/erp'
import settings from './routes/settings'
import management from './routes/management'
import report from './routes/report'
import system from './routes/system'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...erp,
    ...settings,
    ...management,
    ...report,
    ...system,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const checkPermission = isUserGetPermission(to.name)
  // console.log('checkPermission', checkPermission, to)

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  if(checkPermission === false) {
    // console.log('not permission')
    return next({ name: 'misc-not-authorized' })
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
