export default [
  {
    path: '/report-sample',
    name: 'report-sample',
    component: () => import('@/views/report/Sample.vue'),
  },
  {
    path: '/report-sample2',
    name: 'report-sample2',
    component: () => import('@/views/report/Sample-2.vue'),
  },
  {
    path: '/report-sample3',
    name: 'report-sample3',
    component: () => import('@/views/report/Sample-3.vue'),
  },
  {
    path: '/report-sample4',
    name: 'report-sample4',
    component: () => import('@/views/report/Sample-4.vue'),
  },
  {
    path: '/report-sample5',
    name: 'report-sample5',
    component: () => import('@/views/report/Sample-5.vue'),
  },
  {
    path: '/a4-1',
    name: 'a4-1',
    component: () => import('@/views/report/A4-1.vue'),
  },
  {
    path: '/a4-2',
    name: 'a4-2',
    component: () => import('@/views/report/A4-2.vue'),
  },
  {
    path: '/a4-3',
    name: 'a4-3',
    component: () => import('@/views/report/A4-3.vue'),
  },
  {
    path: '/a4-4',
    name: 'a4-4',
    component: () => import('@/views/report/A4-4.vue'),
  },
  {
    path: '/a4-sample',
    name: 'a4-sample',
    component: () => import('@/views/report/A4-sample.vue'),
  },
  {
    path: '/report-requisition',
    name: 'report-requisition',
    component: () => import('@/views/report/requisition.vue'),
  },
  {
    path: '/report-borrow/:id',
    name: 'report-borrow',
    component: () => import('@/views/report/borrow.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Borrow list',
          to: { name: 'borrow-lists' },
        },
        {
          text: 'Borrow Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-ask/:id',
    name: 'report-ask',
    component: () => import('@/views/report/ask.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Ask list',
          to: { name: 'ask-lists' },
        },
        {
          text: 'Ask Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-order/:id',
    name: 'report-order',
    component: () => import('@/views/report/order.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Order list',
          to: { name: 'order-lists' },
        },
        {
          text: 'Order Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-borrow-a4/:id',
    name: 'report-borrow-a4',
    component: () => import('@/views/report/borrow-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Borrow list',
          to: { name: 'borrow-lists' },
        },
        {
          text: 'Borrow A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-ask-a4/:id',
    name: 'report-ask-a4',
    component: () => import('@/views/report/ask-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Ask list',
          to: { name: 'ask-lists' },
        },
        {
          text: 'Ask A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-ask-a4/:id',
    name: 'report-ask-a4',
    component: () => import('@/views/report/ask-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Ask list',
          to: { name: 'ask-lists' },
        },
        {
          text: 'Ask A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-order-a4/:id',
    name: 'report-order-a4',
    component: () => import('@/views/report/order-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Order list',
          to: { name: 'order-lists' },
        },
        {
          text: 'Order A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-import-a4/:id',
    name: 'report-import-a4',
    component: () => import('@/views/report/import-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Import list',
          to: { name: 'import-request-lists' },
        },
        {
          text: 'Import A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-export-a4/:id',
    name: 'report-export-a4',
    component: () => import('@/views/report/export-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Export list',
          to: { name: 'export-lists' },
        },
        {
          text: 'Export A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-pos-a4',
    name: 'report-pos-a4',
    component: () => import('@/views/report/pos-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Export POS',
          to: { name: 'export-pos' },
        },
        {
          text: 'POS A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-pos-a4-export',
    name: 'report-pos-a4-export',
    component: () => import('@/views/report/pos-a4-export.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Export POS',
          to: { name: 'export-by-er' },
        },
        {
          text: 'POS A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-purchase-order-a4/:id',
    name: 'report-purchase-order-a4',
    component: () => import('@/views/report/purchase-order-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Purchase Order',
          to: { name: 'summary-po' },
        },
        {
          text: 'Purchase Order A4 Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-purchase-request-a4/:id',
    name: 'report-purchase-request-a4',
    component: () => import('@/views/report/purchase-request-a4.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Purchase Request',
          to: { name: 'summary-po' },
        },
        {
          text: 'Purchase Request A4 Report',
          active: true,
        },
      ],
    },
  },
]
