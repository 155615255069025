/* eslint-disable implicit-arrow-linebreak */
import i18n from "@/libs/i18n";
import jwtDefaultConfig from "@core/auth/jwt/jwtDefaultConfig";

export default [
  {
    path: "/vendor",
    name: "vendor",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Lists"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Table"),
        },
        {
          text: i18n.t("Supplier Lists"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor',
    }
  },
  {
    path: "/vendor-all",
    name: "vendor-all",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Lists"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Table"),
        },
        {
          text: i18n.t("Supplier Lists"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-all',
    }
  },
  {
    path: "/vendor-oversea",
    name: "vendor-oversea",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Oversea Lists"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Oversea Table"),
        },
        {
          text: i18n.t("Supplier Oversea Lists"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-oversea',
      generateCodeName: 'Vendors Oversea',
    }
  },
  {
    path: "/vendor-domestic",
    name: "vendor-domestic",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Domestic Lists"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Domestic Table"),
        },
        {
          text: i18n.t("Supplier Domestic Lists"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-domestic',
      generateCodeName: 'Vendors Domestic',
    }
  },
  {
    path: "/vendor-transportation",
    name: "vendor-transportation",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Transportation Lists"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Transportation Table"),
        },
        {
          text: i18n.t("Supplier Transportation Lists"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-transportation',
      generateCodeName: 'Vendors Transportation',
    }
  },
  {
    path: "/vendor-shipping-forwarder",
    name: "vendor-shipping-forwarder",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Shipping Forwarder Lists"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Shipping Forwarder Table"),
        },
        {
          text: i18n.t("Supplier Shipping Forwarder Lists"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-shipping-forwarder',
      generateCodeName: 'Vendors Shipping Forwarder',
    }
  },
  {
    path: "/vendor-account",
    name: "vendor-account",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Account Lists"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Account Table"),
        },
        {
          text: i18n.t("Supplier Account Lists"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-account',
      generateCodeName: 'Vendors Account',
    }
  },
  {
    path: "/vendor-add",
    name: "vendor-add",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Add"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor',
    }
  },
  {
    path: "/vendor-oversea-add",
    name: "vendor-oversea-add",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Oversea Add"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Oversea"),
        },
        {
          text: i18n.t("Supplier Oversea Lists"),
          to: { name: "vendor-oversea" },
        },
        {
          text: i18n.t("Supplier Oversea Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-oversea',
      generateCodeName: 'Vendors Oversea',
    }
  },
  {
    path: "/vendor-domestic-add",
    name: "vendor-domestic-add",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Local Add"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Local"),
        },
        {
          text: i18n.t("Supplier Local Lists"),
          to: { name: "vendor-domestic" },
        },
        {
          text: i18n.t("Supplier Local Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-domestic',
      generateCodeName: 'Vendors Domestic',
    }
  },
  {
    path: "/vendor-transportation-add",
    name: "vendor-transportation-add",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Transportation Add"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Transportation"),
        },
        {
          text: i18n.t("Supplier Transportation Lists"),
          to: { name: "vendor-transportation" },
        },
        {
          text: i18n.t("Supplier Transportation Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-transportation',
      generateCodeName: 'Vendors Transportation',
    }
  },
  {
    path: "/vendor-shipping-forwarder-add",
    name: "vendor-shipping-forwarder-add",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Shipping Forwarder Add"),
      breadcrumb: [
        {
          text: i18n.t("Supplier "),
        },
        {
          text: i18n.t("Supplier Shipping Forwarder Lists"),
          to: { name: "vendor-shipping-forwarder" },
        },
        {
          text: i18n.t("Supplier Shipping Forwarder Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-shipping-forwarder',
      generateCodeName: 'Vendors Shipping Forwarder',
    }
  },
  {
    path: "/vendor-account-add",
    name: "vendor-account-add",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Account Add"),
      breadcrumb: [
        {
          text: i18n.t("Supplier Account"),
        },
        {
          text: i18n.t("Supplier Account Lists"),
          to: { name: "vendor-account" },
        },
        {
          text: i18n.t("Supplier Account Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-account',
      generateCodeName: 'Vendors Account',
    }
  },
  {
    path: "/vendor-edit/:id",
    name: "vendor-edit",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Edit"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor',
      generateCodeName: 'Vendors',
    }
    
  },
  {
    path: "/vendor-oversea-edit/:id",
    name: "vendor-oversea-edit",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Edit"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-oversea',
      generateCodeName: 'Vendors Oversea',
    }
  },
  {
    path: "/vendor-domestic-edit/:id",
    name: "vendor-domestic-edit",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Edit"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-domestic',
      generateCodeName: 'Vendors Domestic',
    }
  },
  {
    path: "/vendor-transportation-edit/:id",
    name: "vendor-transportation-edit",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Edit"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-transportation',
      generateCodeName: 'Vendors Transportation',
    }
  },
  {
    path: "/vendor-shipping-forwarder-edit/:id",
    name: "vendor-shipping-forwarder-edit",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Edit"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-shipping-forwarder',
      generateCodeName: 'Vendors Shipping Forwarder',
    }
  },
  {
    path: "/vendor-account-edit/:id",
    name: "vendor-account-edit",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Edit"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'vendor-account',
      generateCodeName: 'Vendors Account',
    }
  },
  {
    path: "/vendor-view/:id",
    name: "vendor-view",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/View.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Detail"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-oversea-view/:id",
    name: "vendor-oversea-view",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/View.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Detail"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-domestic-view/:id",
    name: "vendor-domestic-view",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/View.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Detail"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-transportation-view/:id",
    name: "vendor-transportation-view",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/View.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Detail"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-shipping-forwarder-view/:id",
    name: "vendor-shipping-forwarder-view",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/View.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Detail"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-account-view/:id",
    name: "vendor-account-view",
    component: () =>
      import("@/views/pages/erp/master/vendors/vendorForm/View.vue"),
    meta: {
      pageTitle: i18n.t("Supplier Detail"),
      breadcrumb: [
        {
          text: i18n.t("Supplier"),
        },
        {
          text: i18n.t("Supplier Lists"),
          to: { name: "vendor" },
        },
        {
          text: i18n.t("Supplier Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer",
    name: "customer",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer"),
        },
        {
          text: i18n.t("Customer Lists"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-add",
    name: "customer-add",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Add"),
      breadcrumb: [
        {
          text: i18n.t("Customer"),
        },
        {
          text: i18n.t("Customer Lists"),
          to: { name: "customer" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-edit/:id",
    name: "customer-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer"),
        },
        {
          text: i18n.t("Customer Lists"),
          to: { name: "customer" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-view/:id",
    name: "customer-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer"),
        },
        {
          text: i18n.t("Customer Lists"),
          to: { name: "customer" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-All",
    name: "customer-All",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-All',
      generateCodeName:'All',
    }
  },
  {
    path: "/customer-All-add",
    name: "customer-All-add",
    component: () =>
    import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-All" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-All',
      generateCodeName:'All',
    }
  },
  {
    path: "/customer-All-edit/:id",
    name: "customer-All-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-All" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-All',      
    }
  },
  {
    path: "/customer-All-view/:id",
    name: "customer-All-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-All" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-goverment",
    name: "customer-goverment",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists goverment"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-goverment',
      generateCodeName:'Goverment',
    }
  },
  {
    path: "/customer-goverment-add",
    name: "customer-goverment-add",
    component: () =>
    import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists goverment"),
          to: { name: "customer-goverment" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-goverment',
      generateCodeName:'Goverment',
    }
  },
  {
    path: "/customer-goverment-edit/:id",
    name: "customer-goverment-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-goverment" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-goverment',      
    }
  },
  {
    path: "/customer-goverment-view/:id",
    name: "customer-goverment-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-goverment" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-private",
    name: "customer-private",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists private"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-private',
      generateCodeName:'Private',
    }
  },
  {
    path: "/customer-private-add",
    name: "customer-private-add",
    component: () =>
    import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists private"),
          to: { name: "customer-private" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-private',
      generateCodeName:'Private',
    }
  },
  {
    path: "/customer-private-edit/:id",
    name: "customer-private-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-private" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-private',      
    }
  },
  {
    path: "/customer-private-view/:id",
    name: "customer-private-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-private" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-clinic",
    name: "customer-clinic",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists clinic"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-clinic',
      generateCodeName:'Clinic',
    }
  },
  {
    path: "/customer-clinic-add",
    name: "customer-clinic-add",
    component: () =>
    import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists clinic"),
          to: { name: "customer-clinic" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-clinic',
      generateCodeName:'Clinic',
    }
  },
  {
    path: "/customer-clinic-edit/:id",
    name: "customer-clinic-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-clinic" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-clinic',      
    }
  },
  {
    path: "/customer-clinic-view/:id",
    name: "customer-clinic-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-clinic" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-donator",
    name: "customer-donator",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists donator"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-donator',
      generateCodeName:'Donator',
    }
  },
  {
    path: "/customer-donator-add",
    name: "customer-donator-add",
    component: () =>
    import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists donator"),
          to: { name: "customer-donator" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-donator',
      generateCodeName:'Donator',
    }
  },
  {
    path: "/customer-donator-edit/:id",
    name: "customer-donator-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-donator" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-donator',      
    }
  },
  {
    path: "/customer-donator-view/:id",
    name: "customer-donator-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-donator" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-sub-dealer",
    name: "customer-sub-dealer",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists sub-dealer"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-sub-dealer',
      generateCodeName:'Sub-Dealer',
    }
  },
  {
    path: "/customer-sub-dealer-add",
    name: "customer-sub-dealer-add",
    component: () =>
    import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists sub-dealer"),
          to: { name: "customer-sub-dealer" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-sub-dealer',
      generateCodeName:'Sub-Dealer',
    }
  },
  {
    path: "/customer-sub-dealer-edit/:id",
    name: "customer-sub-dealer-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-sub-dealer" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-sub-dealer',      
    }
  },
  {
    path: "/customer-sub-dealer-view/:id",
    name: "customer-sub-dealer-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-sub-dealer" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-others",
    name: "customer-others",
    component: () =>
      import("@/views/pages/erp/master/customers/customerTable/Lists.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists others"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-others',
      generateCodeName:'Others',
    }
  },
  {
    path: "/customer-others-add",
    name: "customer-others-add",
    component: () =>
    import("@/views/pages/erp/master/customers/customerForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Customer Lists"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists others"),
          to: { name: "customer-others" },
        },
        {
          text: i18n.t("Customer Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-others',
      generateCodeName:'Others',
    }
  },
  {
    path: "/customer-others-edit/:id",
    name: "customer-others-edit",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Customer Edit"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-others" },
        },
        {
          text: i18n.t("Customer Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'customer-others',      
    }
  },
  {
    path: "/customer-others-view/:id",
    name: "customer-others-view",
    component: () =>
      import("@/views/pages/erp/master/customers/customerForm/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Customer Detail"),
      breadcrumb: [
        {
          text: i18n.t("Customer Lists All"),
          to: { name: "customer-others" },
        },
        {
          text: i18n.t("Customer Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/purchase-compare",
    name: "purchase-compare",
    component: () =>
      import("@/views/pages/erp/purchase/compare/PersonCompare.vue"),
    meta: {
      pageTitle: i18n.t("Person Compare Table"),
      breadcrumb: [
        {
          text: i18n.t("Person Compare Table"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-compare',
    }
  },
  {
    path: "/purchase-compare-score-add/:id",
    name: "score",
    component: () =>
      import("@/views/pages/erp/purchase/compare/scoreForm/AddScore.vue"),
    meta: {
      pageTitle: i18n.t("Score Form"),
      breadcrumb: [
        {
          text: i18n.t("Score"),
        },
        {
          text: i18n.t("Score Form"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/all-purchase-compare",
    name: "all-purchase-compare",
    component: () =>
      import("@/views/pages/erp/purchase/compare/AllCompare.vue"),
    meta: {
      pageTitle: i18n.t("All Compare Table"),
      breadcrumb: [
        {
          text: i18n.t("All Compare Table"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-compare',
    }
  },
  {
    path: "/all-purchase-compare-detail/:id",
    name: "all-purchase-compare-detail",
    component: () => import("@/views/pages/erp/purchase/compare/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Issue Detail"),
      breadcrumb: [
        {
          text: i18n.t("Issue"),
        },
        {
          text: i18n.t("Issue Lists"),
          to: "/all-purchase-compare",
        },
        {
          text: i18n.t("Issue Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/all-purchase-compare-edit/:id",
    name: "all-purchase-compare-edit",
    component: () => import("@/views/pages/erp/purchase/compare/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Issue Edit"),
      breadcrumb: [
        {
          text: i18n.t("Issue"),
        },
        {
          text: i18n.t("Issue Lists"),
          to: "/all-purchase-compare" ,
        },
        {
          text: i18n.t("Issue Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-compare',
    }
  },
  {
    path: "/all-purchase-compare-add",
    name: "all-purchase-compare-add",
    component: () =>
      // import("@/views/pages/erp/purchase/compare/issueForm/Add.vue"),
      import("@/views/pages/erp/purchase/compare/Add.vue"),
    meta: {
      pageTitle: i18n.t("Issue Add"),
      breadcrumb: [
        // {
        //   text: i18n.t('Issue'),
        // },
        {
          text: i18n.t("Issue Lists"),
          to: "/all-purchase-compare" ,
        },
        {
          text: i18n.t("Issue Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-compare',
    }
  },
  {
    path: "/purchase-compare-add",
    name: "purchase-compare-add",
    component: () =>
      // import("@/views/pages/erp/purchase/compare/issueForm/Add.vue"),
      import("@/views/pages/erp/purchase/compare/Add.vue"),
    meta: {
      pageTitle: i18n.t("Issue Add"),
      breadcrumb: [
        // {
        //   text: i18n.t('Issue'),
        // },
        {
          text: i18n.t("Issue Lists"),
          to: { name: "purchase-compare" },
        },
        {
          text: i18n.t("Issue Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-compare',
    }
  },
  {
    path: "/purchase-compare-edit/:id",
    name: "purchase-compare-edit",
    component: () => import("@/views/pages/erp/purchase/compare/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Issue Edit"),
      breadcrumb: [
        {
          text: i18n.t("Issue"),
        },
        {
          text: i18n.t("Issue Lists"),
          to: { name: "purchase-compare" },
        },
        {
          text: i18n.t("Issue Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-compare',
    }
  },
  {
    path: "/purchase-compare-detail/:id",
    name: "purchase-compare-detail",
    component: () => import("@/views/pages/erp/purchase/compare/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Issue Detail"),
      breadcrumb: [
        {
          text: i18n.t("Issue"),
        },
        {
          text: i18n.t("Issue Lists"),
          to: "/purchase-compare",
        },
        {
          text: i18n.t("Issue Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/summary-lists",
    name: "summary-lists",
    component: () =>
      import("@/views/pages/erp/purchase/compare/SummaryCompare.vue"),
    meta: {
      pageTitle: i18n.t("Summary Lists"),
      breadcrumb: [
        {
          text: i18n.t("Summary"),
        },
        {
          text: i18n.t("Summary Lists"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/summary-detail/:id",
    name: "summary-detail",
    component: () =>
      import("@/views/pages/erp/purchase/compare/summaryForm/Summary.vue"),
    meta: {
      pageTitle: i18n.t("Summary Detail"),
      breadcrumb: [
        {
          text: i18n.t("Summary"),
        },
        {
          text: i18n.t("Summary Lists"),
        },
        {
          text: i18n.t("Summary Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/person-table-borrow-add",
    name: "person-table-borrow-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [
        {
          text: i18n.t("Borrow"),
          to: "/person-table-borrow",
        },
        {
          text: i18n.t("Create Borrow"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-borrow',
    }
  },
  {
    path: "/person-table-borrow-edit/:id",
    name: "person-table-borrow-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("Borrow"),
          to: "/person-table-borrow",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-borrow',
    }
  },
  {
    path: "/person-table-borrow-detail/:id",
    name: "person-table-borrow-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("Borrow"),
          to: "/person-table-borrow",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-borrow",
    name: "person-table-borrow",
    component: () =>
      import("@/views/pages/erp/sales/borrow/PersonTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("Person Borrow Detail"),
      breadcrumb: [],
    },
    props:{
      linkForGroup:"person-table-borrow"
    },
  },
  {
    path: "/all-borrow",
    name: "all-borrow",
    component: () =>
      import("@/views/pages/erp/sales/borrow/AllTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [],
    },
    props:{
      linkForGroup:"all-borrow"
    },
  },
  {
    path: "/all-borrow-add",
    name: "all-borrow-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [
        {
          text: i18n.t("Borrow"),
          to: "/all-borrow",
        },
        {
          text: i18n.t("Create Borrow"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-borrow"
    },
  },
  {
    path: "/all-borrow-detail/:id",
    name: "all-borrow-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("Borrow"),
          to: "/all-borrow",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
  },
  {
    path: "/all-borrow-edit/:id",
    name: "all-borrow-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Edit"),
      breadcrumb: [
        {
          text: i18n.t("Borrow"),
          to: "/all-borrow",
        },
        {
          text: i18n.t("Borrow Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-borrow"
    },
  },
  {
    path: "/item",
    name: "item",
    component: () =>
      import("@/views/pages/erp/inventory/traceItem/searchItem/Main.vue"),
    meta: {
      pageTitle: i18n.t("Search Item"),
      breadcrumb: [
        {
          text: i18n.t("Search"),
        },
        {
          text: i18n.t("Search Item"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/item-detail/:id",
    name: "item-detail",
    component: () =>
      import("@/views/pages/erp/inventory/traceItem/searchItem/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Item Detail"),
      breadcrumb: [
        {
          text: i18n.t("Search"),
          to: "/item",
        },
        {
          text: i18n.t("Item Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/export-log",
    name: "export-log",
    component: () => import("@/views/pages/erp/inventory/log/Main.vue"),
    meta: {
      pageTitle: i18n.t("Item Export Log"),
      breadcrumb: [
        {
          text: i18n.t("Log"),
        },
      ],
    },
  },
  {
    path: "/inven-stock",
    name: "inven-stock",
    component: () =>
      import("@/views/pages/erp/inventory/returnItem/POS/InvenStock.vue"),
    meta: {
      pageTitle: i18n.t("Item Exports"),
      breadcrumb: [
        {
          text: i18n.t("Item Exports"),
        },
      ],
    },
  },
  {
    path: "/asset-log",
    name: "asset-log",
    component: () =>
      import("@/views/pages/erp/inventory/traceItem/assetLog/Main.vue"),
    meta: {
      pageTitle: i18n.t("Asset Log"),
      breadcrumb: [
        {
          text: i18n.t("Log"),
        },
        {
          text: i18n.t("Asset Log"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/person-table-export",
    name: "person-table-export",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/PersonExportItem.vue"),
    meta: {
      pageTitle: i18n.t("Person Export Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Export Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-export',
    }
  },
  {
    path: "/person-table-export-add",
    name: "person-table-export-add",
    component: () => import("@/views/pages/erp/inventory/exportedItem/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Export"),
      breadcrumb: [
        {
          text: i18n.t("Person Export Detail"),
          to: "/person-table-export",
        },
        {
          text: i18n.t("Create Export"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-export',
    }
  },
  {
    path: "/person-table-export-detail/:id",
    name: "person-table-export-detail",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Export Detail"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/person-table-export",
        },
        {
          text: i18n.t("Export Detail"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-export',
    }
  },
  {
    path: "/person-table-export-edit/:id",
    name: "person-table-export-edit",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Export Edit"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/person-table-export",
        },
        {
          text: i18n.t("Export Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-export',
    }
  },
  
  {
    path: "/all-table-export-detail/:id",
    name: "all-table-export-detail",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Export Detail"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/all-table-export",
        },
        {
          text: i18n.t("Export Detail"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-export',
    }
  },
  {
    path: "/all-table-export-edit/:id",
    name: "all-table-export-edit",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Export Edit"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/all-table-export",
        },
        {
          text: i18n.t("Export Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-export',
    }
  },
  {
    path: "/all-table-export",
    name: "all-table-export",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/AllExportItem.vue"),
    meta: {
      pageTitle: i18n.t("Export Detail"),
      breadcrumb: [
        {
          text: i18n.t("Export Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-export',
    }
  },
  {
    path: "/all-table-export-add",
    name: "all-table-export-add",
    component: () => import("@/views/pages/erp/inventory/exportedItem/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Export"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/all-table-export",
        },
        {
          text: i18n.t("Create Export"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-export',
    }
  },
  {
    path: "/all-table-export-detail/:id",
    name: "all-table-export-detail",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Export Detail"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/all-table-export",
        },
        {
          text: i18n.t("Export Detail"),
        },
      ],
    },
  },
  {
    path: "/all-table-export-edit/:id",
    name: "all-table-export-edit",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Export Edit"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/all-table-export",
        },
        {
          text: i18n.t("Export Edit"),
        },
      ],
    },
  },
  {
    path: "/all-table-packaging",
    name: "all-table-packaging",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/AllPackaging.vue"),
    meta: {
      pageTitle: i18n.t("Packaging"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/all-table-packaging",
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-packaging',
    }
  },
  {
    path: "/all-table-packaging-add",
    name: "all-table-packaging-add",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/Add.vue"),
    meta: {
      pageTitle: i18n.t("Packaging Add"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/all-table-packaging",
        },
        {
          text: i18n.t("Packaging Add"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-packaging',
    }
  },
  {
    path: "/all-table-packaging-edit/:id",
    name: "all-table-packaging-edit",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Packaging Edit"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/all-table-packaging",
        },
        {
          text: i18n.t("Packaging Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-packaging',
    }
  },
  {
    path: "/all-table-packaging-detail/:id",
    name: "all-table-packaging-detail",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Packaging Detail"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/all-table-packaging",
        },
        {
          text: i18n.t("Packaging Detail"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-packaging',
    }
  },
  {
    path: "/person-table-packaging",
    name: "person-table-packaging",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/PersonPackaging.vue"),
    meta: {
      pageTitle: i18n.t("Packaging"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/person-table-packaging",
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-packaging',
    }
  },
  {
    path: "/person-table-packaging-add",
    name: "person-table-packaging-add",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/Add.vue"),
    meta: {
      pageTitle: i18n.t("Packaging Add"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/person-table-packaging",
        },
        {
          text: i18n.t("Packaging Add"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-packaging',
    }
  },
  {
    path: "/person-table-packaging-edit/:id",
    name: "person-table-packaging-edit",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Packaging Edit"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/person-table-packaging",
        },
        {
          text: i18n.t("Packaging Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-packaging',
    }
  },
  {
    path: "/person-table-packaging-detail/:id",
    name: "person-table-packaging-detail",
    component: () =>
      import("@/views/pages/erp/inventory/packaging/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Packaging Detail"),
      breadcrumb: [
        {
          text: i18n.t("Packaging"),
          to: "/person-table-packaging",
        },
        {
          text: i18n.t("Packaging Detail"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-packaging',
    }
  },
  {
    path: "/export-pos",
    name: "export-pos",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/POS/Main.vue"),
    meta: {
      pageTitle: i18n.t("Export POS"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
        },
        {
          text: i18n.t("Export POS"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/export-confirm",
    name: "export-confirm",
    component: () =>
      import("@/views/pages/erp/inventory/exportedItem/POS/ConfirmExport.vue"),
    meta: {
      pageTitle: i18n.t("Export Confirm"),
      breadcrumb: [
        {
          text: i18n.t("Export"),
          to: "/export-pos",
        },
        {
          text: i18n.t("Export Confirm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/inventory-dashboard",
    name: "inventory-dashboard",
    component: () => import("@/views/pages/erp/inventory/dashboard/Main.vue"),
  },
  {
    path: "/purchase-dashboard",
    name: "purchase-dashboard",
    component: () => import("@/views/pages/erp/purchase/dashboard/Main.vue"),
  },
  //PO IN
  {
    path: "/purchase-order-domestic-stock-list/:id",
    name: "purchase-order-domestic-stock-list",
    component: () =>
    import("@/views/pages/erp/inventory/importedItem/importRequest/PersonImportRequest.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/purchase-order-domestic",
        },
        {
          text: i18n.t("Purchase Order Stock Po"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-order-domestic-stock-list',
      isPo:true
    }
  },
  {
    path: "/purchase-order-domestic",
    name: "purchase-order-domestic",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseOrder/PersonPurchaseOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [],
    },
    props:{
      linkForGroup: 'purchase-order-domestic',
      generateCodeName: 'Purchase Order Domestic'
    }
  },
  {
    path: "/purchase-order-domestic-edit/:id",
    name: "purchase-order-domestic-edit",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseOrder/PoEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/purchase-order-domestic",
        },
        {
          text: i18n.t("Purchase Order Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-order-domestic',
      generateCodeName: 'Purchase Order Domestic'
    }
  },
  {
    path: "/purchase-order-domestic-add",
    name: "purchase-order-domestic-add",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseOrder/PoAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Purchase Order"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/purchase-order-domestic",
        },
        {
          text: i18n.t("Create Purchase Order"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-order-domestic',
      generateCodeName: 'Purchase Order Domestic'
    }
  },
  {
    path: "/purchase-order-domestic-detail/:id",
    name: "purchase-order-domestic-detail",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseOrder/PoDetail.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/purchase-order-domestic",
        },
        {
          text: i18n.t("Purchase Order Detail"),
        },
      ],
    },
  },
  {
    path: "/all-purchase-order-domestic-add",
    name: "all-purchase-order-domestic-add",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PoAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Purchase Order"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "all-purchase-order-domestic" },
        },
        {
          text: i18n.t("Create Purchase Order"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-order-domestic',
      generateCodeName: 'Purchase Order Domestic'
    }
  },
  {
    path: "/all-purchase-order-domestic-edit/:id",
    name: "all-purchase-order-domestic-edit",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PoEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/all-purchase-order-domestic",
        },
        {
          text: i18n.t("Purchase Order Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-order-domestic',
      generateCodeName: 'Purchase Order Domestic'
    }
  },
  {
    path: "/all-purchase-order-domestic-detail/:id",
    name: "all-purchase-order-domestic-detail",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PoDetail.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/all-purchase-order-domestic",
        },
        {
          text: i18n.t("Purchase Order Detail"),
        },
      ],
    },
  },
  {
    path: "/all-purchase-order-domestic",
    name: "all-purchase-order-domestic",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseOrder/AllPurchaseOrder.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Detail"),
      breadcrumb: [],
    },
    props:{
      linkForGroup: 'all-purchase-order-domestic',
      generateCodeName: 'Purchase Order Domestic'
    }
  },
  { 
    path: "/all-purchase-order-domestic-stock-list/:id",
    name: "all-purchase-order-domestic-stock-list",
    component: () =>
    import("@/views/pages/erp/inventory/importedItem/importRequest/PersonImportRequest.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/all-purchase-order-domestic",
        },
        {
          text: i18n.t("Purchase Order Stock Po"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-order-domestic-stock-list',
      isPo:true
    }
  },
  //PO OUT
  {
    path: "/purchase-order-oversea-stock-list/:id",
    name: "purchase-order-oversea-stock-list",
    component: () =>
    import("@/views/pages/erp/inventory/importedItem/importRequest/PersonImportRequest.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/purchase-order-oversea",
        },
        {
          text: i18n.t("Purchase Order Stock Po"),
          active:true
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-order-oversea-stock-list',
      isPo:true
    }
  },
  {
    path: "/purchase-order-oversea",
    name: "purchase-order-oversea",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PersonPurchaseOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [],
    },
    props:{
      linkForGroup: 'purchase-order-oversea',
      generateCodeName: 'Purchase Order Oversea'
    }
  },
  {
    path: "/purchase-order-oversea-add",
    name: "purchase-order-oversea-add",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PoAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Purchase Order"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order Out"),
          to: "/purchase-order-oversea",
        },
        {
          text: i18n.t("Create Purchase Order"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-order-oversea',
      generateCodeName: 'Purchase Order Oversea'
    }
  },
  {
    path: "/purchase-order-oversea-detail/:id",
    name: "purchase-order-oversea-detail",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PoDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order Out"),
          to: "/purchase-order-oversea",
        },
        {
          text: i18n.t("Purchase Order Detail"),
        },
      ],
    },
  },
  {
    path: "/purchase-order-oversea-edit/:id",
    name: "purchase-order-oversea-edit",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseOrder/PoEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order Out"),
          to: "/purchase-order-oversea",
        },
        {
          text: i18n.t("Purchase Order Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-order-oversea',
      generateCodeName: 'Purchase Order Oversea'
    }
  },
  {
    path: "/all-purchase-order-oversea-stock-list/:id",
    name: "all-purchase-order-oversea-stock-list",
    component: () =>
    import("@/views/pages/erp/inventory/importedItem/importRequest/PersonImportRequest.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/all-purchase-order-oversea",
        },
        {
          text: i18n.t("Purchase Order Stock Po"),
          active:true
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-order-oversea-stock-list',
      isPo:true
    }
  },
  {
    path: "/all-purchase-order-oversea",
    name: "all-purchase-order-oversea",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/AllPurchaseOrder.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Detail"),
      breadcrumb: [],
    },
    props:{
      linkForGroup: 'all-purchase-order-oversea',
      generateCodeName: 'Purchase Order Oversea'
    }
  },
  {
    path: "/all-purchase-order-oversea-add",
    name: "all-purchase-order-oversea-add",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PoAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Purchase Order"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order Out"),
          to: "/all-purchase-order-oversea",
        },
        {
          text: i18n.t("Create Purchase Order"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-order-oversea',
      generateCodeName: 'Purchase Order Oversea'
    }
  },
  {
    path: "/all-purchase-order-oversea-detail/:id",
    name: "all-purchase-order-oversea-detail",
    component: () =>
    import("@/views/pages/erp/purchase/purchaseOrder/PoDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order Out"),
          to: "/all-purchase-order-oversea",
        },
        {
          text: i18n.t("Create Purchase Order"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/all-purchase-order-oversea-edit/:id",
    name: "all-purchase-order-oversea-edit",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseOrder/PoEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Order"),
          to: "/all-purchase-order-oversea",
        },
        {
          text: i18n.t("Purchase Order Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-order-oversea',
      generateCodeName: 'Purchase Order Oversea'
    }
  },  
  {
    path: "/purchase-request-domestic-add",
    name: "purchase-request-domestic-add",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrAdd.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Add Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "purchase-request-domestic" },
        },
        {
          text: i18n.t("Purchase Request Add Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-request-domestic',
      generateCodeName: 'Purchase Request Domestic'
    }
  },
  {
    path: "/purchase-request-domestic-edit/:id",
    name: "purchase-request-domestic-edit",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Edit Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "purchase-request-domestic" },
        },
        {
          text: i18n.t("Purchase Request Edit Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-request-domestic',
      generateCodeName: 'Purchase Request Domestic'
    }
  },
  {
    path: "/purchase-request-domestic-detail/:id",
    name: "purchase-request-domestic-detail",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrDetail.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Details"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "purchase-request-domestic" },
        },
        {
          text: i18n.t("Purchase Request Details"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/purchase-request-domestic",
    name: "purchase-request-domestic",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PersonPurchase.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Request"),
      breadcrumb: [
        // {
        //   text: i18n.t('Purchase Request'),
        //   to: { name: 'person-purchase-request' },
        // },
        // {
        //   text: i18n.t('Purchase Request Details'),
        //   active: true,
        // },
      ],
    },
    props:{
      linkForGroup: 'purchase-request-domestic',
      generateCodeName: 'Purchase Request Domestic'
    }
  },
  {
    path: "/all-purchase-request-domestic",
    name: "all-purchase-request-domestic",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/AllPurchase.vue"),
    meta: {
      pageTitle: i18n.t("All Purchase Request"),
      breadcrumb: [
        // {
        //   text: i18n.t('Purchase Request'),
        //   to: { name: 'person-purchase-request' },
        // },
        // {
        //   text: i18n.t('Purchase Request Details'),
        //   active: true,
        // },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-request-domestic',
      generateCodeName: 'Purchase Request Domestic'
    }
  },
  {
    path: "/all-purchase-request-domestic-add",
    name: "all-purchase-request-domestic-add",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrAdd.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Add Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "all-purchase-request-domestic" },
        },
        {
          text: i18n.t("Purchase Request Add Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-request-domestic',
      generateCodeName: 'Purchase Request Domestic'
    }
  },
  {
    path: "/all-purchase-request-domestic-edit/:id",
    name: "all-purchase-request-domestic-edit",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Edit Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "all-purchase-request-domestic" },
        },
        {
          text: i18n.t("Purchase Request Edit Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-request-domestic',
      generateCodeName: 'Purchase Request Domestic'
    }
  },
  {
    path: "/all-purchase-request-domestic-detail/:id",
    name: "all-purchase-request-domestic-detail",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrDetail.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Details"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "all-purchase-request-domestic" },
        },
        {
          text: i18n.t("Purchase Request Details"),
          active: true,
        },
      ],
    },
  },

  //PR OUT
  {
    path: "/purchase-request-oversea",
    name: "purchase-request-oversea",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PersonPurchase.vue"),
    meta: {
      pageTitle: i18n.t("Person Purchase Request Out"),
      breadcrumb: [
        // {
        //   text: i18n.t('Purchase Request'),
        //   to: { name: 'person-purchase-request' },
        // },
        // {
        //   text: i18n.t('Purchase Request Details'),
        //   active: true,
        // },
      ],
    },
    props:{
      linkForGroup: 'purchase-request-oversea',
      generateCodeName: 'Purchase Request Oversea'
    }
  },
  {
    path: "/purchase-request-oversea-add",
    name: "purchase-request-oversea-add",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrAdd.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Add Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request Out"),
          to: { name: "purchase-request-oversea" },
        },
        {
          text: i18n.t("Purchase Request Add Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-request-oversea',
      generateCodeName: 'Purchase Request Oversea'
    }
  },
  {
    path: "/purchase-request-oversea-edit/:id",
    name: "purchase-request-oversea-edit",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Edit Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "purchase-request-oversea" },
        },
        {
          text: i18n.t("Purchase Request Edit Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'purchase-request-oversea',
      generateCodeName: 'Purchase Request Oversea'
    }
  },
  {
    path: "/purchase-request-oversea-detail/:id",
    name: "purchase-request-oversea-detail",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrDetail.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Details"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "purchase-request-oversea" },
        },
        {
          text: i18n.t("Purchase Request Details"),
          active: true,
        },
      ],
    },
  },

  {
    path: "/all-purchase-request-oversea-add",
    name: "all-purchase-request-oversea-add",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrAdd.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Add Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "all-purchase-request-oversea" },
        },
        {
          text: i18n.t("Purchase Request Add Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-request-oversea',
      generateCodeName: 'Purchase Request Oversea'
    }
  },
  {
    path: "/all-purchase-request-oversea-edit/:id",
    name: "all-purchase-request-oversea-edit",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrEdit.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Edit Form"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "all-purchase-request-oversea" },
        },
        {
          text: i18n.t("Purchase Request Edit Form"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-request-oversea',
      generateCodeName: 'Purchase Request Oversea'
    }
  },
  {
    path: "/all-purchase-request-oversea-detail/:id",
    name: "all-purchase-request-oversea-detail",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/PrDetail.vue"),
    meta: {
      pageTitle: i18n.t("Purchase Request Details"),
      breadcrumb: [
        {
          text: i18n.t("Purchase Request"),
          to: { name: "all-purchase-request-oversea" },
        },
        {
          text: i18n.t("Purchase Request Details"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/all-purchase-request-oversea",
    name: "all-purchase-request-oversea",
    component: () =>
      import("@/views/pages/erp/purchase/purchaseRequest/AllPurchase.vue"),
    meta: {
      pageTitle: i18n.t("All Purchase Request Out"),
      breadcrumb: [
        // {
        //   text: i18n.t('Purchase Request'),
        //   to: { name: 'person-purchase-request' },
        // },
        // {
        //   text: i18n.t('Purchase Request Details'),
        //   active: true,
        // },
      ],
    },
    props:{
      linkForGroup: 'all-purchase-request-oversea',
      generateCodeName: 'Purchase Request Oversea'
    }
  },
  {
    path: "/product-master",
    name: "product-master",
    component: () =>
      import("@/views/pages/erp/inventory/productMaster/Index.vue"),
    meta: {
      pageTitle: i18n.t("Product Master"),
      breadcrumb: [
        {
          text: i18n.t("Product Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/product-master-add",
    name: "product-master-add",
    component: () =>
      import("@/views/pages/erp/inventory/productMaster/AddPage.vue"),
    meta: {
      pageTitle: i18n.t("Product Master"),
      breadcrumb: [
        {
          text: i18n.t("Product Master"),
          to: { name: "product-master" },
        },
        {
          text: i18n.t("Product Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/product-master-edit/:id",
    name: "product-master-edit",
    component: () =>
      import("@/views/pages/erp/inventory/productMaster/EditPage.vue"),
    meta: {
      pageTitle: i18n.t("Product Master"),
      breadcrumb: [
        {
          text: i18n.t("Product Master"),
          to: { name: "product-master" },
        },
        {
          text: i18n.t("Product Edit"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/product-master-view/:id",
    name: "product-master-view",
    component: () =>
      import("@/views/pages/erp/inventory/productMaster/ViewPage.vue"),
    meta: {
      pageTitle: i18n.t("Product Master"),
      breadcrumb: [
        {
          text: i18n.t("Product Master"),
          to: { name: "product-master" },
        },
        {
          text: i18n.t("Product Detail"),
          active: true,
        },
      ],
    },
  },

  //Cost Item
  {
    path: "/add-cost-item",
    name: "add-cost-item",
    component: () => import("@/views/pages/erp/inventory/importByPO/Main.vue"),
    meta: {
      pageTitle: i18n.t("Add Cost Item"),
      breadcrumb: [
        {
          text: i18n.t("Add Cost Item"),
          active: true,
        },
      ],
    },
    props: {
      isAddCost:true,
    },
  },
  {
    path: "/cost",
    name: "cost",
    component: () =>
      import("@/views/pages/erp/inventory/stock/stockTable/Stock.vue"),
    meta: {
      pageTitle: i18n.t("Show Cost Item"),
      breadcrumb: [
        {
          text: i18n.t("Cost Item"),
          active: true,
        },
      ],
    },
    props: {
      isCost:true,
    },
  },
  // Unit Master
  {
    path: "/unit",
    name: "unit",
    component: () => import("@/views/pages/erp/master/unit/UnitMaster.vue"),
    meta: {
      pageTitle: i18n.t("Unit Master"),
      breadcrumb: [
        {
          text: i18n.t("Unit Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/unit-add",
    name: "unit-add",
    component: () => import("@/views/pages/erp/master/unit/UnitAdd.vue"),
    meta: {
      pageTitle: i18n.t("Unit Add Master"),
      breadcrumb: [
        {
          text: i18n.t("Unit Master"),
          to: { name: "unit" },
        },
        {
          text: i18n.t("Unit Add Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/unit-edit/:id",
    name: "unit-edit",
    component: () => import("@/views/pages/erp/master/unit/UnitEdit.vue"),
    meta: {
      pageTitle: i18n.t("Unit Edit Master"),
      breadcrumb: [
        {
          text: i18n.t("Unit Master"),
          to:{name:"unit"},
        },
        {
          text: i18n.t("Unit Edit Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/unit-view/:id",
    name: "unit-view",
    component: () => import("@/views/pages/erp/master/unit/UnitView.vue"),
    meta: {
      pageTitle: i18n.t("Unit View Master"),
      breadcrumb: [
        {
          text: i18n.t("Unit Master"),
          to:{name:"unit"},
        },
        {
          text: i18n.t("Unit View Master"),
          active: true,
        },
      ],
    },
  },
  // Item Position Master
  {
    path: "/item-position",
    name: "item-position",
    component: () =>
      import("@/views/pages/erp/master/itemPosition/ItemPositionMaster.vue"),
    meta: {
      pageTitle: i18n.t("Item Position Master"),
      breadcrumb: [
        {
          text: i18n.t("Item Position Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/item-position-lists-add",
    name: "item-position-lists-add",
    component: () =>
      import("@/views/pages/erp/master/itemPosition/ItemPositionAdd.vue"),
    meta: {
      pageTitle: i18n.t("Item Position Add Master"),
      breadcrumb: [
        {
          text: i18n.t("Item Position Master"),
          to: { name: "item-position" },
        },
        {
          text: i18n.t("Item Position Add Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/item-position-lists-edit/:id",
    name: "item-position-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/itemPosition/ItemPositionEdit.vue"),
    meta: {
      pageTitle: i18n.t("Item Position Edit Master"),
      breadcrumb: [
        {
          text: i18n.t("Item Position Master"),
          to: { name: "item-position" },
        },
        {
          text: i18n.t("Item Position Edit Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/item-position-view/:id",
    name: "item-position-view",
    component: () =>
      import("@/views/pages/erp/master/itemPosition/ItemPositionView.vue"),
    meta: {
      pageTitle: i18n.t("Item Position View Master"),
      breadcrumb: [
        {
          text: i18n.t("Item Position Master"),
          to: { name: "item-position" },
        },
        {
          text: i18n.t("Item Position View Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/position-lists",
    name: "position-lists",
    component: () => import("@/views/pages/erp/master/position/List.vue"),
    meta: {
      pageTitle: i18n.t("Position List"),
      breadcrumb: [
        {
          text: i18n.t("Position"),
        },
        {
          text: i18n.t("Position List"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/position-lists-add",
    name: "position-lists-add",
    component: () =>
      import("@/views/pages/erp/master/position/PositionAdd.vue"),
    meta: {
      pageTitle: i18n.t("Position Add"),
      breadcrumb: [
        {
          text: i18n.t("Position"),
        },
        {
          text: i18n.t("Position List"),
          to: { name: "position-lists" },
        },
        {
          text: i18n.t("Position Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/position-lists-edit/:id",
    name: "position-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/position/PositionEdit.vue"),
    meta: {
      pageTitle: i18n.t("Position Edit"),
      breadcrumb: [
        {
          text: i18n.t("Position"),
        },
        {
          text: i18n.t("Position List"),
          to: { name: "position-lists" },
        },
        {
          text: i18n.t("Position Edit"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/position-lists-detail/:id",
    name: "position-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/position/PositionDetail.vue"),
    meta: {
      pageTitle: i18n.t("Position Detail"),
      breadcrumb: [
        {
          text: i18n.t("Position"),
        },
        {
          text: i18n.t("Position List"),
          to: { name: "position-lists" },
        },
        {
          text: i18n.t("Position Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/category-lists",
    name: "category-lists",
    component: () => import("@/views/pages/erp/master/category/List.vue"),
    meta: {
      pageTitle: i18n.t("Category List"),
      breadcrumb: [
        {
          text: i18n.t("Category"),
        },
        {
          text: i18n.t("Category List"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/category-lists-add",
    name: "category-lists-add",
    component: () =>
      import("@/views/pages/erp/master/category/CategoryAdd.vue"),
    meta: {
      pageTitle: i18n.t("Category Add"),
      breadcrumb: [
        {
          text: i18n.t("Category"),
        },
        {
          text: i18n.t("Category List"),
          to: { name: "category-lists" },
        },
        {
          text: i18n.t("Category Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/category-lists-edit/:id",
    name: "category-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/category/CategoryEdit.vue"),
    meta: {
      pageTitle: i18n.t("Category Edit"),
      breadcrumb: [
        {
          text: i18n.t("Category"),
        },
        {
          text: i18n.t("Category List"),
          to: { name: "category-lists" },
        },
        {
          text: i18n.t("Category Edit"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/category-lists-detail/:id",
    name: "category-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/category/CategoryDetail.vue"),
    meta: {
      pageTitle: i18n.t("Category Detail"),
      breadcrumb: [
        {
          text: i18n.t("Category"),
        },
        {
          text: i18n.t("Category List"),
          to: { name: "category-lists" },
        },
        {
          text: i18n.t("Category Detail"),
          active: true,
        },
      ],
    },
  },
  // -------------------------------------- Product Code Main -------------------------------------------
  {
    path: "/product-code-main-lists",
    name: "product-code-main-lists",
    component: () => import("@/views/pages/erp/master/productCode/List.vue"),
    meta: {
      pageTitle: i18n.t("Product Code List"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-main-lists',
      type: 'Main Product'
    }
  },
  {
    path: "/product-code-main-lists-add",
    name: "product-code-main-lists-add",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeAdd.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Add"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-main-lists" },
        },
        {
          text: i18n.t("Product Code Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-main-lists',
      type: 'Main Product'
    }
  },
  {
    path: "/product-code-main-lists-edit/:id",
    name: "product-code-main-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeEdit.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Edit"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-main-lists" },
        },
        {
          text: i18n.t("Product Code Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-main-lists',
      type: 'Main Product'
    }
  },
  {
    path: "/product-code-main-lists-detail/:id",
    name: "product-code-main-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeDetail.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Detail"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-main-lists" },
        },
        {
          text: i18n.t("Product Code Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-main-lists',
      type: 'Main Product'
    }
  },
// -------------------------------------- Product Code Department -------------------------------------------
  {
    path: "/product-code-department-lists",
    name: "product-code-department-lists",
    component: () => import("@/views/pages/erp/master/productCode/List.vue"),
    meta: {
      pageTitle: i18n.t("Product Code List"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-department-lists',
      type: 'Department'
    }
  },
  {
    path: "/product-code-department-lists-add",
    name: "product-code-department-lists-add",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeAdd.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Add"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-department-lists" },
        },
        {
          text: i18n.t("Product Code Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-department-lists',
      type: 'Department'
    }
  },
  {
    path: "/product-code-department-lists-edit/:id",
    name: "product-code-department-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeEdit.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Edit"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-department-lists" },
        },
        {
          text: i18n.t("Product Code Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-department-lists',
      type: 'Department'
    }
  },
  {
    path: "/product-code-department-lists-detail/:id",
    name: "product-code-department-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeDetail.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Detail"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-department-lists" },
        },
        {
          text: i18n.t("Product Code Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-department-lists',
      type: 'Department'
    }
  },
  // -------------------------------------- Product Code Group -------------------------------------------
  {
    path: "/product-code-group-lists",
    name: "product-code-group-lists",
    component: () => import("@/views/pages/erp/master/productCode/List.vue"),
    meta: {
      pageTitle: i18n.t("Product Code List"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-group-lists',
      type: 'Group'
    }
  },
  {
    path: "/product-code-group-lists-add",
    name: "product-code-group-lists-add",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeAdd.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Add"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-group-lists" },
        },
        {
          text: i18n.t("Product Code Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-group-lists',
      type: 'Group'
    }
  },
  {
    path: "/product-code-group-lists-edit/:id",
    name: "product-code-group-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeEdit.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Edit"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-group-lists" },
        },
        {
          text: i18n.t("Product Code Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-group-lists',
      type: 'Group'
    }
  },
  {
    path: "/product-code-group-lists-detail/:id",
    name: "product-code-group-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeDetail.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Detail"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-group-lists" },
        },
        {
          text: i18n.t("Product Code Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-group-lists',
      type: 'Group'
    }
  },
  // -------------------------------------- Product Code Type -------------------------------------------
  {
    path: "/product-code-type-lists",
    name: "product-code-type-lists",
    component: () => import("@/views/pages/erp/master/productCode/List.vue"),
    meta: {
      pageTitle: i18n.t("Product Code List"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-type-lists',
      type: 'Type'
    }
  },
  {
    path: "/product-code-type-lists-add",
    name: "product-code-type-lists-add",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeAdd.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Add"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-type-lists" },
        },
        {
          text: i18n.t("Product Code Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-type-lists',
      type: 'Type'
    }
  },
  {
    path: "/product-code-type-lists-edit/:id",
    name: "product-code-type-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeEdit.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Edit"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-type-lists" },
        },
        {
          text: i18n.t("Product Code Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-type-lists',
      type: 'Type'
    }
  },
  {
    path: "/product-code-type-lists-detail/:id",
    name: "product-code-type-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeDetail.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Detail"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-type-lists" },
        },
        {
          text: i18n.t("Product Code Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-type-lists',
      type: 'Type'
    }
  },
  // -------------------------------------- Product Code Brand -------------------------------------------
  {
    path: "/product-code-brand-lists",
    name: "product-code-brand-lists",
    component: () => import("@/views/pages/erp/master/productCode/List.vue"),
    meta: {
      pageTitle: i18n.t("Product Code List"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-brand-lists',
      type: 'Brand'
    }
  },
  {
    path: "/product-code-brand-lists-add",
    name: "product-code-brand-lists-add",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeAdd.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Add"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-brand-lists" },
        },
        {
          text: i18n.t("Product Code Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-brand-lists',
      type: 'Brand'
    }
  },
  {
    path: "/product-code-brand-lists-edit/:id",
    name: "product-code-brand-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeEdit.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Edit"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-brand-lists" },
        },
        {
          text: i18n.t("Product Code Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-brand-lists',
      type: 'Brand'
    }
  },
  {
    path: "/product-code-brand-lists-detail/:id",
    name: "product-code-brand-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeDetail.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Detail"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-brand-lists" },
        },
        {
          text: i18n.t("Product Code Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-brand-lists',
      type: 'Brand'
    }
  },
  // -------------------------------------- Product Code Model -------------------------------------------
  {
    path: "/product-code-model-lists",
    name: "product-code-model-lists",
    component: () => import("@/views/pages/erp/master/productCode/List.vue"),
    meta: {
      pageTitle: i18n.t("Product Code List"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-model-lists',
      type: 'Model'
    }
  },
  {
    path: "/product-code-model-lists-add",
    name: "product-code-model-lists-add",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeAdd.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Add"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-model-lists" },
        },
        {
          text: i18n.t("Product Code Add"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-model-lists',
      type: 'Model'
    }
  },
  {
    path: "/product-code-model-lists-edit/:id",
    name: "product-code-model-lists-edit",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeEdit.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Edit"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-model-lists" },
        },
        {
          text: i18n.t("Product Code Edit"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-model-lists',
      type: 'Model'
    }
  },
  {
    path: "/product-code-model-lists-detail/:id",
    name: "product-code-model-lists-detail",
    component: () =>
      import("@/views/pages/erp/master/productCode/MasterProductCodeDetail.vue"),
    meta: {
      pageTitle: i18n.t("Product Code Detail"),
      breadcrumb: [
        {
          text: i18n.t("Product Code"),
        },
        {
          text: i18n.t("Product Code List"),
          to: { name: "product-code-model-lists" },
        },
        {
          text: i18n.t("Product Code Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'product-code-model-lists',
      type: 'Model'
    }
  },
  {
    path: "/site",
    name: "site",
    component: () => import("@/views/pages/erp/master/site/SiteMaster.vue"),
    meta: {
      pageTitle: i18n.t("Site Master"),
      breadcrumb: [
        {
          text: i18n.t("Site Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/site-add",
    name: "site-add",
    component: () => import("@/views/pages/erp/master/site/SiteAdd.vue"),
    meta: {
      pageTitle: i18n.t("Site Add Master"),
      breadcrumb: [
        {
          text: i18n.t("Site Master"),
          to: "/site",
        },
        {
          text: i18n.t("Site Add Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/site-edit/:id",
    name: "site-edit",
    component: () => import("@/views/pages/erp/master/site/SiteEdit.vue"),
    meta: {
      pageTitle: i18n.t("Site Edit Master"),
      breadcrumb: [
        {
          text: i18n.t("Site Master"),
          to:{name:"site"},
        },
        {
          text: i18n.t("Site Edit Master"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/site-view/:id",
    name: "site-view",
    component: () => import("@/views/pages/erp/master/site/SiteView.vue"),
    meta: {
      pageTitle: i18n.t("Site View Master"),
      breadcrumb: [
        {
          text: i18n.t("Site Master"),
          to:{name:"site"},
        },
        {
          text: i18n.t("Site View Master"),
          active: true,
        },
      ],
    },
  },
  //userarea
  {
    path: "/userarea",
    name: "userarea",
    component: () => import("@/views/pages/erp/master/UserArea/List.vue"),
    meta: {
      pageTitle: i18n.t("UserArea List"),
      breadcrumb: [
        {
          text: i18n.t("UserArea"),
        },
        {
          text: i18n.t("UserArea List"),
          active: true,
        },
      ],
    },
  },

  {
    path: "/userarea-add",
    name: "userarea-add",
    component: () =>
      import("@/views/pages/erp/master/UserArea/UserAreaAdd.vue"),
    meta: {
      pageTitle: i18n.t("UserArea Add"),
      breadcrumb: [
        {
          text: i18n.t("UserArea"),
        },
        {
          text: i18n.t("UserArea List"),
          to: { name: "userarea" },
        },
        {
          text: i18n.t("UserArea Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/userarea-edit/:id",
    name: "userarea-edit",
    component: () =>
      import("@/views/pages/erp/master/UserArea/UserAreaEdit.vue"),
    meta: {
      pageTitle: i18n.t("UserArea Edit"),
      breadcrumb: [
        {
          text: i18n.t("UserArea"),
        },
        {
          text: i18n.t("UserArea List"),
          to: { name: "userarea" },
        },
        {
          text: i18n.t("UserArea Edit"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/userarea-detail/:id",
    name: "userarea-detail",
    component: () =>
      import("@/views/pages/erp/master/UserArea/UserAreaDetail.vue"),
    meta: {
      pageTitle: i18n.t("UserArea Detail"),
      breadcrumb: [
        {
          text: i18n.t("UserArea"),
        },
        {
          text: i18n.t("UserArea List"),
          to: { name: "userarea" },
        },
        {
          text: i18n.t("UserArea Detail"),
          active: true,
        },
      ],
    },
  },
  // Project
  {
    path: "/project-pms",
    name: "project-pms",
    component: () => import("@/views/pages/erp/project/Main.vue"),
    meta: {
      pageTitle: i18n.t("Project-PMS"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
          active: true,
        },
      ],
    },
    props: {
      title:'',
      generateCodeName: "PMS",
    },
  },
  {
    path: "/project-uis",
    name: "project-uis",
    component: () => import("@/views/pages/erp/project/Main.vue"),
    meta: {
      pageTitle: i18n.t("Project-UIS"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
          active: true,
        },
      ],
    },
    props: {
      title:'',
      generateCodeName: "UIS",
    },
  },
  {
    path: "/project-csv",
    name: "project-csv",
    component: () => import("@/views/pages/erp/project/Main.vue"),
    meta: {
      pageTitle: i18n.t("Project-CSV"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
          active: true,
        },
      ],
    },
    props: {
      title:'',
      generateCodeName: "CSV",
    },
  },
  {
    path: "/project-dashboard",
    name: "project-dashboard",
    component: () => import("@/views/pages/erp/project/Dashboard.vue"),
    meta: {
      pageTitle: i18n.t("Dashboard"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-project-add",
    name: "create-project-add",
    component: () =>
      import("@/views/pages/erp/project/createdProject/ProjectAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Project Add"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
        },
        {
          text: i18n.t("Create Project Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/project-pms-detail/:id",
    name: "project-pms-detail",
    component: () => import("@/views/pages/erp/project/ProjectDetail.vue"),
    meta: {
      pageTitle: i18n.t("Update Project Form"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
          to: "/project-pms",
        },
        {
          text: i18n.t("Update Project Form"),
          active: true,
        },
      ],
    },
    // props: true,
    // beforeEnter(to, _, next) {
    //   if(to.params.generateCodeName){
    //     next()
    //   }else{
    //     next({ name: 'project-pms' })
    //   }
    // },
    props: {
      generateCodeName: "PMS",
    },
  },
  {
    path: "/project-uis-detail/:id",
    name: "project-uis-detail",
    component: () => import("@/views/pages/erp/project/ProjectDetail.vue"),
    meta: {
      pageTitle: i18n.t("Update Project Form"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
          to: "/project-uis",
        },
        {
          text: i18n.t("Update Project Form"),
          active: true,
        },
      ],
    },
    // props: true,
    // beforeEnter(to, _, next) {
    //   console.log('to:', to)
    //   if(to.params.generateCodeName){
    //     next()
    //   }else{
    //     next({ name: 'project-uis' })
    //   }
    // },
    props: {
      generateCodeName: "UIS",
    },
  },
  {
    path: "/project-csv-detail/:id",
    name: "project-csv-detail",
    component: () => import("@/views/pages/erp/project/ProjectDetail.vue"),
    meta: {
      pageTitle: i18n.t("Update Project Form"),
      breadcrumb: [
        {
          text: i18n.t("Job"),
          to: "/project-csv",
        },
        {
          text: i18n.t("Update Project Form"),
          active: true,
        },
      ],
    },
    // props: true,
    // beforeEnter(to, _, next) {
    //   console.log('to:', to.params)
    //   if(to.params.generateCodeName){
    //     next()
    //   }else{
    //     next({ name: 'project-csv' })
    //   }
    // },
    props: {
      generateCodeName: "CSV",
    },
  },
  
  {
    path: "/sale-dashboard",
    name: "sale-dashboard",
    component: () => import("@/views/pages/erp/sales/Dashboard.vue"),
    meta: {
      pageTitle: i18n.t("Dashboard"),
      breadcrumb: [
        {
          text: i18n.t("Sales"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/person-table-quotation-pms-detail/:id",
    name: "person-table-quotation-pms-detail",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/person-table-quotation-pms",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-quotation-uis-detail/:id",
    name: "person-table-quotation-uis-detail",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/person-table-quotation-uis",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-quotation-csv-detail/:id",
    name: "person-table-quotation-csv-detail",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/person-table-quotation-csv",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-quotation-pms",
    name: "person-table-quotation-pms",
    component: () =>
      import("@/views/pages/erp/sales/quotation/PersonQuotation.vue"),
    meta: {
      pageTitle: i18n.t("Person Quotation Detail PMS"),
      breadcrumb: [
        // {
        //   text: i18n.t('Quotation'),
        //   to: '/person-quotation',
        // },
        // {
        //   text: i18n.t('Quotation Detail'),
        // },
      ],
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-quotation-pms",
      CollectionAPI: jwtDefaultConfig.quEndPoint,
      pageCategory: "quotation"
    },
  },
  {
    path: "/all-table-close-job-pms",
    name: "all-table-close-job-pms",
    component: () =>
      import("@/views/pages/erp/sales/quotation/AllCloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "all-table-close-job-pms"
    },
  },
  {
    path: "/all-table-close-job-uis",
    name: "all-table-close-job-uis",
    component: () =>
      import("@/views/pages/erp/sales/quotation/AllCloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "UIS",
      linkForGroup: "all-table-close-job-uis"
    },
  },
  {
    path: "/all-table-close-job-csv",
    name: "all-table-close-job-csv",
    component: () =>
      import("@/views/pages/erp/sales/quotation/AllCloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "CSV",
      linkForGroup: "all-table-close-job-csv"
    },
  },

  {
    path: "/all-table-close-job-pms-detail/:id",
    name: "all-table-close-job-pms-detail",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "all-table-close-job-pms"
    },
  },
  {
    path: "/all-table-close-job-uis-detail/:id",
    name: "all-table-close-job-uis-detail",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "UIS",
      linkForGroup: "all-table-close-job-uis"
    },
  },
  {
    path: "/all-table-close-job-csv-detail/:id",
    name: "all-table-close-job-csv-detail",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "CSV",
      linkForGroup: "all-table-close-job-csv"
    },
  },
  {
    path: "/all-table-close-job-pms-edit/:id",
    name: "all-table-close-job-pms-edit",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsEdit.vue"),
    meta: {
      pageTitle: i18n.t("Person Edit Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "all-table-close-job-pms"
    },
  },
  {
    path: "/all-table-close-job-uis-edit/:id",
    name: "all-table-close-job-uis-edit",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsEdit.vue"),
    meta: {
      pageTitle: i18n.t("Person Edit Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "UIS",
      linkForGroup: "all-table-close-job-uis"
    },
  },
  {
    path: "/all-table-close-job-csv-edit/:id",
    name: "all-table-close-job-csv-edit",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsEdit.vue"),
    meta: {
      pageTitle: i18n.t("Person Edit Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "CSV",
      linkForGroup: "all-table-close-job-csv"
    },
  },
  {
    path: "/person-table-close-job-pms",
    name: "person-table-close-job-pms",
    component: () =>
      import("@/views/pages/erp/sales/quotation/PersonCloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "person-table-close-job-pms"
    },
  },
  {
    path: "/person-table-close-job-uis",
    name: "person-table-close-job-uis",
    component: () =>
      import("@/views/pages/erp/sales/quotation/PersonCloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "UIS",
      linkForGroup: "person-table-close-job-uis"
    },
  },
  {
    path: "/person-table-close-job-csv",
    name: "person-table-close-job-csv",
    component: () =>
      import("@/views/pages/erp/sales/quotation/PersonCloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "CSV",
      linkForGroup: "person-table-close-job-csv"
    },
  },

  {
    path: "/person-table-close-job-pms-detail/:id",
    name: "person-table-close-job-pms-detail",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "person-table-close-job-pms"
    },
  },
  {
    path: "/person-table-close-job-uis-detail/:id",
    name: "person-table-close-job-uis-detail",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "UIS",
      linkForGroup: "person-table-close-job-uis"
    },
  },
  {
    path: "/person-table-close-job-csv-detail/:id",
    name: "person-table-close-job-csv-detail",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "CSV",
      linkForGroup: "person-table-close-job-csv"
    },
  },
  {
    path: "/person-table-close-job-pms-edit/:id",
    name: "person-table-close-job-pms-edit",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsEdit.vue"),
    meta: {
      pageTitle: i18n.t("Person Edit Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "person-table-close-job-pms"
    },
  },
  {
    path: "/person-table-close-job-uis-edit/:id",
    name: "person-table-close-job-uis-edit",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsEdit.vue"),
    meta: {
      pageTitle: i18n.t("Person Edit Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "UIS",
      linkForGroup: "person-table-close-job-uis"
    },
  },
  {
    path: "/person-table-close-job-csv-edit/:id",
    name: "person-table-close-job-csv-edit",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsEdit.vue"),
    meta: {
      pageTitle: i18n.t("Person Edit Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "CSV",
      linkForGroup: "person-table-close-job-csv"
    },
  },
  {
    path: "/all-table-quotation-pms",
    name: "all-table-quotation-pms",
    component: () =>
      import("@/views/pages/erp/sales/quotation/AllQuotation.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail PMS"),
      breadcrumb: [],
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-quotation-pms",
      CollectionAPI: jwtDefaultConfig.quEndPoint,
      pageCategory: "quotation"
    },
  },
  {
    path: "/all-table-quotation-pms-detail/:id",
    name: "all-table-quotation-pms",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/all-table-quotation-pms",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
  },
  {
    path: "/all-table-quotation-pms-edit/:id",
    name: "all-table-quotation-pms",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/all-table-quotation-pms",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-quotation-pms",      
      pageCategory: "quotation"
    },
  },
  {
    path: "/person-table-quotation-uis",
    name: "person-table-quotation-uis",
    component: () =>
      import("@/views/pages/erp/sales/quotation/PersonQuotation.vue"),
    meta: {
      pageTitle: i18n.t("Person Quotation Detail UIS"),
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-quotation-uis",
      CollectionAPI: jwtDefaultConfig.quEndPoint,
      pageCategory: "quotation"
    },
  },
  {
    path: "/all-table-quotation-uis",
    name: "all-table-quotation-uis",
    component: () =>
      import("@/views/pages/erp/sales/quotation/AllQuotation.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail UIS"),
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-quotation-uis",
      CollectionAPI: jwtDefaultConfig.quEndPoint,
      pageCategory: "quotation"
    },
  },
  {
    path: "/all-table-quotation-uis-detail/:id",
    name: "all-table-quotation-uis",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/all-table-quotation-uis",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
  },
  
  {
    path: "/all-table-quotation-uis-edit/:id",
    name: "all-table-quotation-uis",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/all-table-quotation-uis",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-quotation-uis",
      pageCategory: "quotation"
    },
  },

  {
    path: "/person-table-quotation-csv",
    name: "person-table-quotation-csv",
    component: () =>
      import("@/views/pages/erp/sales/quotation/PersonQuotation.vue"),
    meta: {
      pageTitle: i18n.t("Person Quotation Detail CSV"),
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-quotation-csv",
      CollectionAPI: jwtDefaultConfig.quEndPoint,
      pageCategory: "quotation"
    },
  },
  {
    path: "/all-table-quotation-csv",
    name: "all-table-quotation-csv",
    component: () =>
      import("@/views/pages/erp/sales/quotation/AllQuotation.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail CSV"),
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-quotation-csv",
      CollectionAPI: jwtDefaultConfig.quEndPoint,
      pageCategory: "quotation"
    },
  },
  {
    path: "/all-table-quotation-csv-detail/:id",
    name: "all-table-quotation-csv",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/all-table-quotation-csv",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
  },
  {
    path: "/all-table-quotation-csv-edit/:id",
    name: "all-table-quotation-csv",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/all-table-quotation-csv",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-quotation-csv",
      pageCategory: "quotation"
    },
  },

  {
    path: "/person-table-quotation-pms-edit/:id",
    name: "person-table-quotation-pms-edit",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Edit"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/person-table-quotation-pms",
        },
        {
          text: i18n.t("Quotation Edit"),
        },
      ],
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-quotation-pms",
      pageCategory: "quotation"
    },
  },
  {
    path: "/person-table-quotation-uis-edit/:id",
    name: "person-table-quotation-uis-edit",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Edit"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/person-table-quotation-uis",
        },
        {
          text: i18n.t("Quotation Edit"),
        },
      ],
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-quotation-uis",
      pageCategory: "quotation"
    },
  },
  {
    path: "/person-table-quotation-csv-edit/:id",
    name: "person-table-quotation-csv-edit",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Edit"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/person-table-quotation-csv",
        },
        {
          text: i18n.t("Quotation Edit"),
        },
      ],
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-quotation-csv",
      pageCategory: "quotation"
    },
  },



  {
    path: "/Person-Table-Approve-PMS",
    name: "Person-Table-Approve-PMS",
    component: () => import("@/views/pages/erp/approveProject/ApproveProject.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Approve Job-PMS"),
    },
    props: {
      typeQuo: "Project PMS",
      generateCodeName: "PMS",
      linkForGroup: "Person-Table-Approve-PMS"
    },
  },
  {
    path: "/All-Table-Approve-PMS",
    name: "All-Table-Approve-PMS",
    component: () => import("@/views/pages/erp/approveProject/AllApproveProject.vue"),
    meta: {
      pageTitle: i18n.t("All Table Approve Job-PMS"),
    },
    props: {
      typeQuo: "Project PMS",
      generateCodeName: "PMS",
      linkForGroup: "All-Table-Approve-PMS"
    },
  },
  {
    path: "/Person-Table-Approve-UIS",
    name: "Person-Table-Approve-UIS",
    component: () => import("@/views/pages/erp/approveProject/ApproveProject.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Approve Job-UIS"),
    },
    props: {
      typeQuo: "Project UIS",
      generateCodeName: "UIS",
      linkForGroup: "Person-Table-Approve-UIS"
    },
  },
  {
    path: "/All-Table-Approve-UIS",
    name: "All-Table-Approve-UIS",
    component: () => import("@/views/pages/erp/approveProject/AllApproveProject.vue"),
    meta: {
      pageTitle: i18n.t("All Table Approve Job-UIS"),
    },
    props: {
      typeQuo: "Project UIS",
      generateCodeName: "UIS",
      linkForGroup: "All-Table-Approve-UIS"
    },
  },
  {
    path: "/Person-Table-Approve-CSV",
    name: "Person-Table-Approve-CSV",
    component: () => import("@/views/pages/erp/approveProject/ApproveProject.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Approve Job-CSV"),
    },
    props: {
      typeQuo: "Project CSV",
      generateCodeName: "CSV",
      linkForGroup: "Person-Table-Approve-CSV"
    },
  },
  {
    path: "/All-Table-Approve-CSV",
    name: "All-Table-Approve-CSV",
    component: () => import("@/views/pages/erp/approveProject/AllApproveProject.vue"),
    meta: {
      pageTitle: i18n.t("All Table Approve Job-CSV"),
    },
    props: {
      typeQuo: "Project CSV",
      generateCodeName: "CSV",
      linkForGroup: "All-Table-Approve-CSV"
    },
  },




  {
    path: "/admin-table-quotation-pms",
    name: "admin-table-quotation-pms",
    component: () => import("@/views/pages/erp/admin/quotation/Quotation.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail PMS"),
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "admin-table-quotation-pms",
      pageCategory: 'quotation'
    },
  },
  // person
  {
    path: "/admin-person-table-quotation-pms",
    name: "admin-person-table-quotation-pms",
    component: () => import("@/views/pages/erp/admin/quotation/PersonQu.vue"),
    meta: {
      pageTitle: i18n.t("Quotation PMS Admin"),
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "admin-person-table-quotation-pms",
      pageCategory: 'quotation'
    },
  },
  // detail
  {
    path: "/admin-person-table-quotation-pms-detail/:id",
    name: "admin-person-table-quotation-pms",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail PMS Admin"),
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "admin-person-table-quotation-pms",
      pageCategory: 'quotation'
    },
  },
  // edit
  {
    path: "/admin-person-table-quotation-pms-edit/:id",
    name: "admin-person-table-quotation-pms",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail PMS Admin"),
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "admin-person-table-quotation-pms",
      pageCategory: 'quotation'
    },
  },
  {
    path: "/admin-table-quotation-pms-detail/:id",
    name: "admin-table-quotation-pms-detail",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/admin-table-quotation-pms",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "admin-table-quotation-pms",
      admin: true,
      pageCategory: 'quotation'
    },
  },
  {
    path: "/admin-table-quotation-pms-edit/:id",
    name: "admin-table-quotation-pms",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/admin-table-quotation-pms",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
    props: {
      type: "Quotation PMS",
      generateCodeName: "PMS",
      linkForGroup: "admin-table-quotation-pms",
      admin: true,
      pageCategory: 'quotation'
    },
  },
  {
    path: "/admin-table-quotation-uis",
    name: "admin-table-quotation-uis",
    component: () => import("@/views/pages/erp/admin/quotation/Quotation.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail UIS"),
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "admin-table-quotation-uis",
      pageCategory: 'quotation'
    },
  },
  // person uis
  {
    path: "/admin-person-table-quotation-uis",
    name: "admin-person-table-quotation-uis",
    component: () => import("@/views/pages/erp/admin/quotation/PersonQu.vue"),
    meta: {
      pageTitle: i18n.t("Quotation UIS Admin"),
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "admin-person-table-quotation-uis",
      pageCategory: 'quotation'
    },
  },
  // detail
  {
    path: "/admin-person-table-quotation-uis-detail/:id",
    name: "admin-person-table-quotation-uis",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail UIS Admin"),
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "admin-person-table-quotation-uis",
      pageCategory: 'quotation'
    },
  },
  //edit
  {
    path: "/admin-person-table-quotation-uis-edit/:id",
    name: "admin-person-table-quotation-uis",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail UIS Admin"),
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "admin-person-table-quotation-uis",
      pageCategory: 'quotation'
    },
  },
  {
    path: "/admin-table-quotation-uis-detail/:id",
    name: "admin-table-quotation-uis-detail",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/admin-table-quotation-uis",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "admin-table-quotation-uis",
      admin: true,
      pageCategory: 'quotation'
    },
  },
  {
    path: "/admin-table-quotation-uis-edit/:id",
    name: "admin-table-quotation-uis",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      breadcrumb: [
        {
          text: i18n.t("Quotation"),
          to: "/admin-table-quotation-uis",
        },
        {
          text: i18n.t("Quotation Detail"),
        },
      ],
    },
    props: {
      type: "Quotation UIS",
      generateCodeName: "UIS",
      linkForGroup: "admin-table-quotation-uis",
      admin: true,
      pageCategory: 'quotation'
    },
  },
  {
    path: "/admin-table-quotation-csv",
    name: "admin-table-quotation-csv",
    component: () => import("@/views/pages/erp/admin/quotation/Quotation.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail CSV"),
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "admin-table-quotation-csv",
      pageCategory: 'quotation'
    },
  },
  // person
  {
    path: "/admin-person-table-quotation-csv",
    name: "admin-person-table-quotation-csv",
    component: () => import("@/views/pages/erp/admin/quotation/PersonQu.vue"),
    meta: {
      pageTitle: i18n.t("Quotation CSV Admin"),
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "admin-person-table-quotation-csv",
      pageCategory: 'quotation'
    },
  },
  // detail

  {
    path: "/admin-person-table-quotation-csv-detail/:id",
    name: "admin-person-table-quotation-csv",
    component: () => import("@/views/pages/erp/sales/quotation/QuDetail.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail CSV Admin"),
      // breadcrumb: [
      //   {
      //     text: i18n.t("Quotation"),
      //     to: "/admin-table-quotation-csv",
      //   },
      //   {
      //     text: i18n.t("Quotation Detail"),
      //   },
      // ],
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "admin-person-table-quotation-csv",
      admin: true,
      pageCategory: 'quotation'
    },
  },
  {
    path: "/admin-person-table-quotation-csv-edit/:id",
    name: "admin-person-table-quotation-csv",
    component: () => import("@/views/pages/erp/sales/quotation/QuEdit.vue"),
    meta: {
      pageTitle: i18n.t("Quotation Detail"),
      // breadcrumb: [
      //   {
      //     text: i18n.t("Quotation"),
      //     to: "/admin-table-quotation-csv",
      //   },
      //   {
      //     text: i18n.t("Quotation Detail"),
      //   },
      // ],
    },
    props: {
      type: "Quotation CSV",
      generateCodeName: "CSV",
      linkForGroup: "admin-person-table-quotation-csv",
      admin: true,
      pageCategory: 'quotation'
    },
  },

  //--------------------------------- admin Close Job -----------------------------------------
  {
    path: "/admin-table-close-job-pms",
    name: "admin-table-close-job-pms",
    component: () =>
      import("@/views/pages/erp/admin/closejob/CloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "admin-table-close-job-pms"
    },
  },
  {
    path: "/admin-table-close-job-uis",
    name: "admin-table-close-job-uis",
    component: () =>
      import("@/views/pages/erp/admin/closejob/CloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "UIS",
      linkForGroup: "admin-table-close-job-uis"
    },
  },
  {
    path: "/admin-table-close-job-csv",
    name: "admin-table-close-job-csv",
    component: () =>
      import("@/views/pages/erp/admin/closejob/CloseJob.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "CSV",
      linkForGroup: "admin-table-close-job-csv"
    },
  },
  {
    path: "/admin-table-close-job-pms-detail/:id",
    name: "admin-table-close-job-pms-detail",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsDetail.vue"),
    meta: {
      pageTitle: i18n.t("Person Detail Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "admin-table-close-job-pms"
    },
  },
  {
    path: "/admin-table-close-job-pms-edit/:id",
    name: "admin-table-close-job-pms-edit",
    component: () =>
      import("@/views/pages/erp/sales/quotation/CloseJobsEdit.vue"),
    meta: {
      pageTitle: i18n.t("Person Edit Close Job"),
      breadcrumb: [],
    },
    props: {
      generateCodeName: "PMS",
      linkForGroup: "admin-table-close-job-pms",
      admin: true
    },
  },


  {
    path: "/accounting-dashboard",
    name: "accounting-dashboard",
    component: () => import("@/views/pages/erp/accounting/Dashboard.vue"),
    meta: {
      pageTitle: i18n.t("Dashboard"),
      breadcrumb: [
        {
          text: i18n.t("Accounting"),
          active: true,
        },
      ],
    },
  },
  // ------------------------------------------ Billing --------------------------------------
  {
    path: "/all-table-billing-pms",
    name: "all-table-billing-pms",
    component: () =>
      import("@/views/pages/erp/accounting/billing/AllBilling.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail PMS"),
      breadcrumb: [],
    },
    props: {
      type: "Bill PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-billing-pms",
      CollectionAPI: jwtDefaultConfig.blEndPoint,
      pageCategory:  "bill"
    },
  },
  {
    path: "/all-table-billing-pms-detail/:id",
    name: "all-table-billing-pms",
    component: () => import("@/views/pages/erp/accounting/billing/BillDetail.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/all-table-billing-pms",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Bill PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-billing-pms",
      pageCategory:  "bill",
    },
  },
  {
    path: "/all-table-billing-pms-edit/:id",
    name: "all-table-billing-pms",
    component: () => import("@/views/pages/erp/accounting/billing/BillEdit.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/all-table-billing-pms",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-billing-pms",
      pageCategory:  "bill",
    },
  },
  {
    path: "/person-table-billing-pms",
    name: "person-table-billing-pms",
    component: () =>
      import("@/views/pages/erp/accounting/billing/PersonBilling.vue"),
    meta: {
      pageTitle: i18n.t("Person Billing Detail PMS"),
      breadcrumb: [],
    },
    props: {
      type: "Bill PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-billing-pms",
      CollectionAPI: jwtDefaultConfig.blEndPoint,
      pageCategory:  "bill"
    },
  },
  {
    path: "/person-table-billing-pms-detail/:id",
    name: "person-table-billing-pms",
    component: () => import("@/views/pages/erp/accounting/billing/BillDetail.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/person-table-billing-pms",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-billing-pms",
      pageCategory:  "bill",
    },
  },
  {
    path: "/person-table-billing-pms-edit/:id",
    name: "person-table-billing-pms",
    component: () => import("@/views/pages/erp/accounting/billing/BillEdit.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/person-table-billing-pms",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-billing-pms",
      pageCategory:  "bill",
    },
  },
  {
    path: "/all-table-billing-uis",
    name: "all-table-billing-uis",
    component: () =>
      import("@/views/pages/erp/accounting/billing/AllBilling.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail UIS"),
      breadcrumb: [],
    },
    props: {
      type: "Bill UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-billing-uis",
      CollectionAPI: jwtDefaultConfig.blEndPoint,
      pageCategory:  "bill"
    },
  },
  {
    path: "/all-table-billing-uis-detail/:id",
    name: "all-table-billing-uis",
    component: () => import("@/views/pages/erp/accounting/billing/BillDetail.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/all-table-billing-uis",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-billing-uis",
      pageCategory:  "bill",
    },
  },
  {
    path: "/all-table-billing-uis-edit/:id",
    name: "all-table-billing-uis",
    component: () => import("@/views/pages/erp/accounting/billing/BillEdit.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/all-table-billing-uis",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-billing-uis",
      pageCategory:  "bill",
    },
  },
  {
    path: "/person-table-billing-uis",
    name: "person-table-billing-uis",
    component: () =>
      import("@/views/pages/erp/accounting/billing/PersonBilling.vue"),
    meta: {
      pageTitle: i18n.t("Person Billing Detail UIS"),
      breadcrumb: [],
    },
    props: {
      type: "Bill UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-billing-uis",
      CollectionAPI: jwtDefaultConfig.blEndPoint,
      pageCategory:  "bill"
    },
  },
  {
    path: "/person-table-billing-uis-detail/:id",
    name: "person-table-billing-uis",
    component: () => import("@/views/pages/erp/accounting/billing/BillDetail.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/person-table-billing-uis",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-billing-uis",
      pageCategory:  "bill",
    },
  },
  {
    path: "/person-table-billing-uis-edit/:id",
    name: "person-table-billing-uis",
    component: () => import("@/views/pages/erp/accounting/billing/BillEdit.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/person-table-billing-uis",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-billing-uis",
      pageCategory:  "bill",
    },
  },
  {
    path: "/all-table-billing-csv",
    name: "all-table-billing-csv",
    component: () =>
      import("@/views/pages/erp/accounting/billing/AllBilling.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail CSV"),
      breadcrumb: [],
    },
    props: {
      type: "Bill CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-billing-csv",
      CollectionAPI: jwtDefaultConfig.blEndPoint,
      pageCategory:  "bill",
    },
  },
  {
    path: "/all-table-billing-csv-detail/:id",
    name: "all-table-billing-csv",
    component: () => import("@/views/pages/erp/accounting/billing/BillDetail.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/all-table-billing-csv",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-billing-csv",
      pageCategory:  "bill",
    },
  },
  {
    path: "/all-table-billing-csv-edit/:id",
    name: "all-table-billing-csv",
    component: () => import("@/views/pages/erp/accounting/billing/BillEdit.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/all-table-billing-csv",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-billing-csv",
      pageCategory:  "bill",
    },
  },
  {
    path: "/person-table-billing-csv",
    name: "person-table-billing-csv",
    component: () =>
      import("@/views/pages/erp/accounting/billing/PersonBilling.vue"),
    meta: {
      pageTitle: i18n.t("Person Billing Detail CSV"),
      breadcrumb: [],
    },
    props: {
      type: "Bill CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-billing-csv",
      CollectionAPI: jwtDefaultConfig.blEndPoint,
      pageCategory:  "bill"
    },
  },
  {
    path: "/person-table-billing-csv-detail/:id",
    name: "person-table-billing-csv",
    component: () => import("@/views/pages/erp/accounting/billing/BillDetail.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/person-table-billing-csv",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-billing-csv",
      pageCategory:  "bill",
    },
  },
  {
    path: "/person-table-billing-csv-edit/:id",
    name: "person-table-billing-csv",
    component: () => import("@/views/pages/erp/accounting/billing/BillEdit.vue"),
    meta: {
      pageTitle: i18n.t("Billing Detail"),
      breadcrumb: [
        {
          text: i18n.t("Billing"),
          to: "/person-table-billing-csv",
        },
        {
          text: i18n.t("Billing Detail"),
        },
      ],
    },
    props: {
      type: "Billing CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-billing-csv",
      pageCategory:  "bill",
    },
  },
  // ------------------------------------------ Receipt --------------------------------------
  {
    path: "/all-table-receipt-pms",
    name: "all-table-receipt-pms",
    component: () =>
      import("@/views/pages/erp/accounting/receipt/AllReceipt.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail PMS"),
      breadcrumb: [],
    },
    props: {
      type: "Receipt PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-receipt-pms",
      CollectionAPI: jwtDefaultConfig.reEndPoint,
      pageCategory:  "receipt",
    },
  },
  {
    path: "/all-table-receipt-pms-detail/:id",
    name: "all-table-receipt-pms",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptDetail.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/all-table-receipt-pms",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-receipt-pms",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/all-table-receipt-pms-edit/:id",
    name: "all-table-receipt-pms",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptEdit.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/all-table-receipt-pms",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-receipt-pms",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/person-table-receipt-pms",
    name: "person-table-receipt-pms",
    component: () =>
      import("@/views/pages/erp/accounting/receipt/PersonReceipt.vue"),
    meta: {
      pageTitle: i18n.t("Person Receipt Detail PMS"),
      breadcrumb: [],
    },
    props: {
      type: "Receipt PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-receipt-pms",
      CollectionAPI: jwtDefaultConfig.reEndPoint,
      pageCategory:  "receipt"
    },
  },
  {
    path: "/person-table-receipt-pms-detail/:id",
    name: "person-table-receipt-pms",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptDetail.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/person-table-receipt-pms",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-receipt-pms",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/person-table-receipt-pms-edit/:id",
    name: "person-table-receipt-pms",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptEdit.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/person-table-receipt-pms",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-receipt-pms",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/all-table-receipt-uis",
    name: "all-table-receipt-uis",
    component: () =>
      import("@/views/pages/erp/accounting/receipt/AllReceipt.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail UIS"),
      breadcrumb: [],
    },
    props: {
      type: "Receipt UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-receipt-uis",
      CollectionAPI: jwtDefaultConfig.reEndPoint,
      pageCategory:  "receipt"
    },
  },
  {
    path: "/all-table-receipt-uis-detail/:id",
    name: "all-table-receipt-uis",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptDetail.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/all-table-receipt-uis",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-receipt-uis",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/all-table-receipt-uis-edit/:id",
    name: "all-table-receipt-uis",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptEdit.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/all-table-receipt-uis",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-receipt-uis",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/person-table-receipt-uis",
    name: "person-table-receipt-uis",
    component: () =>
      import("@/views/pages/erp/accounting/receipt/PersonReceipt.vue"),
    meta: {
      pageTitle: i18n.t("Person Receipt Detail UIS"),
      breadcrumb: [],
    },
    props: {
      type: "Receipt UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-receipt-uis",
      CollectionAPI: jwtDefaultConfig.reEndPoint,
      pageCategory:  "receipt"
    },
  },
  {
    path: "/person-table-receipt-uis-detail/:id",
    name: "person-table-receipt-uis",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptDetail.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/person-table-receipt-uis",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-receipt-uis",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/person-table-receipt-uis-edit/:id",
    name: "person-table-receipt-uis",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptEdit.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/person-table-receipt-uis",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-receipt-uis",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/all-table-receipt-csv",
    name: "all-table-receipt-csv",
    component: () =>
      import("@/views/pages/erp/accounting/receipt/AllReceipt.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail CSV"),
      breadcrumb: [],
    },
    props: {
      type: "Receipt CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-receipt-csv",
      CollectionAPI: jwtDefaultConfig.reEndPoint,
      pageCategory:  "receipt",
    },
  },
  {
    path: "/all-table-receipt-csv-detail/:id",
    name: "all-table-receipt-csv",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptDetail.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/all-table-receipt-csv",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-receipt-csv",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/all-table-receipt-csv-edit/:id",
    name: "all-table-receipt-csv",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptEdit.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/all-table-receipt-csv",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-receipt-csv",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/person-table-receipt-csv",
    name: "person-table-receipt-csv",
    component: () =>
      import("@/views/pages/erp/accounting/receipt/PersonReceipt.vue"),
    meta: {
      pageTitle: i18n.t("Person Receipt Detail CSV"),
      breadcrumb: [],
    },
    props: {
      type: "Receipt CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-receipt-csv",
      CollectionAPI: jwtDefaultConfig.reEndPoint,
      pageCategory:  "receipt"
    },
  },
  {
    path: "/person-table-receipt-csv-detail/:id",
    name: "person-table-receipt-csv",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptDetail.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/person-table-receipt-csv",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-receipt-csv",
      pageCategory:  "receipt",
    },
  },
  {
    path: "/person-table-receipt-csv-edit/:id",
    name: "person-table-receipt-csv",
    component: () => import("@/views/pages/erp/accounting/receipt/ReceiptEdit.vue"),
    meta: {
      pageTitle: i18n.t("Receipt Detail"),
      breadcrumb: [
        {
          text: i18n.t("Receipt"),
          to: "/person-table-receipt-csv",
        },
        {
          text: i18n.t("Receipt Detail"),
        },
      ],
    },
    props: {
      type: "Receipt CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-receipt-csv",
      pageCategory:  "receipt",
    },
  },
  // ------------------------------------------ Cash Invoice --------------------------------------
  {
    path: "/all-table-cash-invoice-pms",
    name: "all-table-cash-invoice-pms",
    component: () =>
      import("@/views/pages/erp/accounting/cash-invoice/AllCashInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail PMS"),
      breadcrumb: [],
    },
    props: {
      type: "Cash Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-cash-invoice-pms",
      CollectionAPI: jwtDefaultConfig.cainvEndPoint,
      pageCategory:  "cashInvoice"
    },
  },
  {
    path: "/all-table-cash-invoice-pms-detail/:id",
    name: "all-table-cash-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceDetail.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/all-table-cash-invoice-pms",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-cash-invoice-pms",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/all-table-cash-invoice-pms-edit/:id",
    name: "all-table-cash-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceEdit.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/all-table-cash-invoice-pms",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-cash-invoice-pms",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-pms",
    name: "person-table-cash-invoice-pms",
    component: () =>
      import("@/views/pages/erp/accounting/cash-invoice/PersonCashInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Person Cash Invoice Detail PMS"),
      breadcrumb: [],
    },
    props: {
      type: "Cash Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-cash-invoice-pms",
      CollectionAPI: jwtDefaultConfig.cainvEndPoint,
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-pms-detail/:id",
    name: "person-table-cash-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceDetail.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/person-table-cash-invoice-pms",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-cash-invoice-pms",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-pms-edit/:id",
    name: "person-table-cash-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceEdit.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/person-table-cash-invoice-pms",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-cash-invoice-pms",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/all-table-cash-invoice-uis",
    name: "all-table-cash-invoice-uis",
    component: () =>
      import("@/views/pages/erp/accounting/cash-invoice/AllCashInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail UIS"),
      breadcrumb: [],
    },
    props: {
      type: "Cash Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-cash-invoice-uis",
      CollectionAPI: jwtDefaultConfig.cainvEndPoint,
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/all-table-cash-invoice-uis-detail/:id",
    name: "all-table-cash-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceDetail.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/all-table-cash-invoice-uis",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-cash-invoice-uis",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/all-table-cash-invoice-uis-edit/:id",
    name: "all-table-cash-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceEdit.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/all-table-cash-invoice-uis",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-cash-invoice-uis",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-uis",
    name: "person-table-cash-invoice-uis",
    component: () =>
      import("@/views/pages/erp/accounting/cash-invoice/PersonCashInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Person Cash Invoice Detail UIS"),
      breadcrumb: [],
    },
    props: {
      type: "Cash Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-cash-invoice-uis",
      CollectionAPI: jwtDefaultConfig.cainvEndPoint,
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-uis-detail/:id",
    name: "person-table-cash-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceDetail.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/person-table-cash-invoice-uis",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-cash-invoice-uis",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-uis-edit/:id",
    name: "person-table-cash-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceEdit.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/person-table-cash-invoice-uis",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-cash-invoice-uis",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/all-table-cash-invoice-csv",
    name: "all-table-cash-invoice-csv",
    component: () =>
      import("@/views/pages/erp/accounting/cash-invoice/AllCashInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail CSV"),
      breadcrumb: [],
    },
    props: {
      type: "Cash Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-cash-invoice-csv",
      CollectionAPI: jwtDefaultConfig.cainvEndPoint,
      pageCategory:  "cashInvoice"
    },
  },
  {
    path: "/all-table-cash-invoice-csv-detail/:id",
    name: "all-table-cash-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceDetail.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/all-table-cash-invoice-csv",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-cash-invoice-csv",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/all-table-cash-invoice-csv-edit/:id",
    name: "all-table-cash-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceEdit.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/all-table-cash-invoice-csv",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-cash-invoice-csv",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-csv",
    name: "person-table-cash-invoice-csv",
    component: () =>
      import("@/views/pages/erp/accounting/cash-invoice/PersonCashInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Person Cash Invoice Detail CSV"),
      breadcrumb: [],
    },
    props: {
      type: "Cash Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-cash-invoice-csv",
      CollectionAPI: jwtDefaultConfig.cainvEndPoint,
      pageCategory:  "cashInvoice"
    },
  },
  {
    path: "/person-table-cash-invoice-csv-detail/:id",
    name: "person-table-cash-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceDetail.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/person-table-cash-invoice-csv",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-cash-invoice-csv",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/person-table-cash-invoice-csv-edit/:id",
    name: "person-table-cash-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/cash-invoice/CashInvoiceEdit.vue"),
    meta: {
      pageTitle: i18n.t("Cash Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Cash Invoice"),
          to: "/person-table-cash-invoice-csv",
        },
        {
          text: i18n.t("Cash Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Cash Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-cash-invoice-csv",
      pageCategory:  "cashInvoice",
    },
  },
  {
    path: "/invoice-add",
    name: "invoice-add",
    component: () => import("@/views/pages/erp/accounting/invoice/IvAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Invoice"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-invoice",
        },
        {
          text: i18n.t("Create Invoice"),
          active: true,
        },
      ],
    },    
  },
  {
    path: "/person-table-invoice",
    name: "person-table-invoice",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/PersonTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Person Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-invoice',
      generateCodeName: "Invoice",
    }
  },
  {
    path: "/person-table-invoice-add",
    name: "person-table-invoice-add",
    component: () => import("@/views/pages/erp/accounting/invoice/IvAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Invoice"),
      breadcrumb: [
        {
          text: i18n.t("Create Invoice"),
          to: "/person-table-invoice",
        },
        {
          text: i18n.t("Person Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-invoice',
      generateCodeName: "Invoice",
    }
  },
  {
    path: "/person-table-invoice-edit/:id",
    name: "person-table-invoice-edit",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Edit"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice",
        },
        {
          text: i18n.t("Invoice Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-invoice',
      generateCodeName: "Invoice",
    }
  },
  {
    path: "/person-table-invoice-detail/:id",
    name: "person-table-invoice-detail",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-table-invoice',
      generateCodeName: "Invoice",
    }
  },
  {
    path: "/all-table-invoice",
    name: "all-table-invoice",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/AllTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-invoice',
      generateCodeName: "Invoice",
    }
  },
  {
    path: "/all-table-invoice-pms",
    name: "all-table-invoice-pms",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/AllTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      type: "Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: 'all-table-invoice-pms',
      CollectionAPI: jwtDefaultConfig.invEndPoint,
      pageCategory:  "invoice"
    }
  },
  {
    path: "/all-table-invoice-pms-detail/:id",
    name: "all-table-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice-pms",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
  },
  {
    path: "/all-table-invoice-pms-edit/:id",
    name: "all-table-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice-pms",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "all-table-invoice-pms"
    },
  },
  {
    path: "/person-table-invoice-pms",
    name: "person-table-invoice-pms",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/PersonTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      type: "Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: 'person-table-invoice-pms',
      CollectionAPI: jwtDefaultConfig.invEndPoint,
      pageCategory:  "invoice"
    }
  },
  {
    path: "/person-table-invoice-pms-detail/:id",
    name: "person-table-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice-pms",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-invoice-pms-edit/:id",
    name: "person-table-invoice-pms",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice-pms",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Invoice PMS",
      generateCodeName: "PMS",
      linkForGroup: "person-table-invoice-pms"
    },
  },
  {
    path: "/all-table-invoice-uis",
    name: "all-table-invoice-uis",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/AllTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      type: "Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: 'all-table-invoice-uis',
      CollectionAPI: jwtDefaultConfig.invEndPoint,
      pageCategory:  "invoice"
    }
  },
  {
    path: "/all-table-invoice-uis-detail/:id",
    name: "all-table-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice-uis",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
  },
  {
    path: "/all-table-invoice-uis-edit/:id",
    name: "all-table-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice-uis",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "all-table-invoice-uis"
    },
  },
  {
    path: "/person-table-invoice-uis",
    name: "person-table-invoice-uis",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/PersonTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      type: "Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: 'person-table-invoice-uis',
      CollectionAPI: jwtDefaultConfig.invEndPoint,
      pageCategory:  "invoice"
    }
  },
  {
    path: "/person-table-invoice-uis-detail/:id",
    name: "person-table-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice-uis",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-invoice-uis-edit/:id",
    name: "person-table-invoice-uis",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice-uis",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Invoice UIS",
      generateCodeName: "UIS",
      linkForGroup: "person-table-invoice-uis"
    },
  },
  {
    path: "/all-table-invoice-csv",
    name: "all-table-invoice-csv",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/AllTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      type: "Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: 'all-table-invoice-csv',
      CollectionAPI: jwtDefaultConfig.invEndPoint,
      pageCategory:  "invoice"
    }
  },
  {
    path: "/all-table-invoice-csv-detail/:id",
    name: "all-table-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice-csv",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
  },
  {
    path: "/all-table-invoice-csv-edit/:id",
    name: "all-table-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice-csv",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "all-table-invoice-csv"
    },
  },
  {
    path: "/person-table-invoice-csv",
    name: "person-table-invoice-csv",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/PersonTableInvoice.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      type: "Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: 'person-table-invoice-csv',
      CollectionAPI: jwtDefaultConfig.invEndPoint,
      pageCategory:  "invoice"
    }
  },
  {
    path: "/person-table-invoice-csv-detail/:id",
    name: "person-table-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice-csv",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-invoice-csv-edit/:id",
    name: "person-table-invoice-csv",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/person-table-invoice-csv",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props: {
      type: "Invoice CSV",
      generateCodeName: "CSV",
      linkForGroup: "person-table-invoice-csv"
    },
  },
  {
    path: "/all-table-invoice-add",
    name: "all-table-invoice-add",
    component: () => import("@/views/pages/erp/accounting/invoice/IvAdd.vue"),
    meta: {
      pageTitle: i18n.t("Create Invoice"),
      breadcrumb: [
        {
          text: i18n.t("Create Invoice"),
          to: "/all-table-invoice",
        },
        {
          text: i18n.t("Person Invoice Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-invoice',
      generateCodeName: "Invoice",
    }
  },
  {
    path: "/all-table-invoice-detail/:id",
    name: "all-table-invoice-detail",
    component: () =>
      import("@/views/pages/erp/accounting/invoice/IvDetail.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Detail"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice",
        },
        {
          text: i18n.t("Invoice Detail"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-invoice',
      generateCodeName: "Invoice",
    }
  },
  {
    path: "/all-table-invoice-edit/:id",
    name: "all-table-invoice-edit",
    component: () => import("@/views/pages/erp/accounting/invoice/IvEdit.vue"),
    meta: {
      pageTitle: i18n.t("Invoice Edit"),
      breadcrumb: [
        {
          text: i18n.t("Invoice"),
          to: "/all-table-invoice",
        },
        {
          text: i18n.t("Invoice Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-table-invoice',
      generateCodeName: "Invoice",
    }
  },

  {
    path: "/cost-table",
    name: "cost-table",
    component: () => import("@/views/pages/erp/accounting/cost/Cost.vue"),
    meta: {
      pageTitle: i18n.t("Cost"),
      breadcrumb: [
        {
          text: i18n.t("Cost Table"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/cost-table-add",
    name: "cost-table-add",
    component: () => import("@/views/pages/erp/accounting/cost/CostForm.vue"),
    meta: {
      pageTitle: i18n.t("Cost"),
      breadcrumb: [
        // {
        //   text: i18n.t('Cost'),
        // },
        {
          text: i18n.t("Cost Table"),
          to: { name: "cost-calendar" },
        },
        {
          text: i18n.t("Cost Form"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/cost-table-edit/:id",
    name: "cost-table-edit",
    component: () => import("@/views/pages/erp/accounting/cost/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Cost"),
      breadcrumb: [
        {
          text: i18n.t("Cost"),
        },
        {
          text: i18n.t("Cost Table"),
          to: { name: "cost-table" },
        },
        {
          text: i18n.t("Cost Edit"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/cost-table-detail/:id",
    name: "cost-table-detail",
    component: () => import("@/views/pages/erp/accounting/cost/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Cost"),
      breadcrumb: [
        {
          text: i18n.t("Cost"),
        },
        {
          text: i18n.t("Cost Table"),
          to: { name: "cost-table" },
        },
        {
          text: i18n.t("Cost Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/cost-calendar",
    name: "cost-calendar",
    component: () =>
      import("@/views/pages/erp/accounting/cost/calendar/Calendar.vue"),
    meta: {
      pageTitle: i18n.t("Cost"),
      breadcrumb: [
        {
          text: i18n.t("Cost Calendar"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/stock-lists",
    name: "stock-lists",
    component: () =>
      import("@/views/pages/erp/inventory/stock/stockTable/Stock.vue"),
    meta: {
      pageTitle: i18n.t("Stock Lists"),
      breadcrumb: [
        {
          text: i18n.t("Stock Item"),
        },
        {
          text: i18n.t("Stock Lists"),
          active: true,
        },
      ],
    },
    props: {
      isCost:false,
    },
  },
  {
    path: "/stock-item-lists/:id",
    name: "stock-item-lists",
    component: () =>
      import("@/views/pages/erp/inventory/stock/stockItemTable/StockItem.vue"),
    meta: {
      pageTitle: i18n.t("Stock Item List"),
      breadcrumb: [
        {
          text: i18n.t("Stock Item"),
          to: { name: "stock-lists" },
        },
        {
          text: i18n.t("Stock Lists"),
          to: { name: "stock-lists" },
        },
        {
          text: i18n.t("Stock Item List"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/stock-lists-add",
    name: "/stock-lists-add",
    component: () =>
      import("@/views/pages/erp/inventory/stock/stockForm/Add.vue"),
    meta: {
      pageTitle: i18n.t("Stock Add"),
      breadcrumb: [
        {
          text: i18n.t("Stock Item"),
        },
        {
          text: i18n.t("Stock Lists"),
          to: { name: "stock-lists" },
        },
        {
          text: i18n.t("Stock Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/stock-item-print-label/:id",
    name: "stock-item-print-label",
    component: () =>
      import(
        "@/views/pages/erp/inventory/stock/stockItemTable/PrintLabelPage.vue"
      ),
    meta: {
      pageTitle: i18n.t("Stock Item List"),
      breadcrumb: [
        {
          text: i18n.t("Stock Item"),
        },
        {
          text: i18n.t("Stock Item List"),
          to: { name: "stock-item-lists" },
        },
        {
          text: i18n.t("Print Label"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/stock-edit/:id",
    name: "stock-edit",
    component: () =>
      import("@/views/pages/erp/inventory/stock/stockForm/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Stock Edit"),
      breadcrumb: [
        {
          text: i18n.t("Stock Item"),
        },
        {
          text: i18n.t("Stock Lists"),
          to: { name: "stock-lists" },
        },
        {
          text: i18n.t("Stock Edit"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/stock-detail/:id",
    name: "stock-detail",
    component: () =>
      import("@/views/pages/erp/inventory/stock/stockForm/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Stock Detail"),
      breadcrumb: [
        {
          text: i18n.t("Stock Item"),
        },
        {
          text: i18n.t("Stock Lists"),
          to: { name: "stock-lists" },
        },
        {
          text: i18n.t("Stock Detail"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/setting-exchange-rate",
    name: "setting-exchange-rate",
    component: () => import("@/views/pages/erp/currency/ExchangeForm.vue"),
    meta: {
      pageTitle: i18n.t("Setting"),
      breadcrumb: [
        {
          text: i18n.t("Setting"),
        },
        {
          text: i18n.t("Exchange Rate"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/setting-app",
    name: "setting-app",
    component: () => import("@/views/pages/settings/systemApp/systemApp.vue"),
    meta: {
      pageTitle: i18n.t("Setting App"),
      breadcrumb: [
        {
          text: i18n.t("Setting App"),
        },
      ],
    },
  },
  {
    path: "/person-import-approval",
    name: "person-import-approval",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/importRequest/PersonImportRequest.vue"),
    meta: {
      pageTitle: i18n.t("Person Import Approval Table"),
      breadcrumb: [
        {
          text: i18n.t("Person Import Approval Table"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-import-approval",
      isPo:false
    },
  },
  {
    path: "/person-import-approval-detail/:id",
    name: "person-import-approval-detail",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/importRequest/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Import Detail"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
          to: "/person-import-approval",
        },
        {
          text: i18n.t("Import Detail"),
        },
      ],
    },
  },
  {
    path: "/person-import-approval-edit/:id",
    name: "person-import-approval-edit",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/importRequest/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Import Edit"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
          to: "/person-import-approval",
        },
        {
          text: i18n.t("Import Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'person-import-approval',
    }
  },
  {
    path: "/person-import-approval-add",
    name: "person-import-approval-add",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/importRequest/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Import"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
          to: "/person-import-approval",
        },
        {
          text: i18n.t("Create Import"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'person-import-approval',
    }
  },
  {
    path: "/all-import-approval",
    name: "all-import-approval",
    component: () =>
      import(
        "@/views/pages/erp/inventory/importedItem/importRequest/AllImportRequest.vue"
      ),
    meta: {
      pageTitle: i18n.t("Import Detail"),
      breadcrumb: [
        {
          text: i18n.t("Import Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-import-approval"
    },
  },
  {
    path: "/all-import-approval-add",
    name: "all-import-approval-add",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/importRequest/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Import"),
      breadcrumb: [
        {
          text: i18n.t("Import Detail"),
          to: "/all-import-approval",
        },
        {
          text: i18n.t("Import Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup: 'all-import-approval',
    }
  },
  {
    path: "/all-import-approval-detail/:id",
    name: "all-import-approval-detail",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/importRequest/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Import Detail"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
          to: "/all-import-approval",
        },
        {
          text: i18n.t("Import Detail"),
        },
      ],
    },
  },
  {
    path: "/all-import-approval-edit/:id",
    name: "all-import-approval-edit",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/importRequest/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Import Edit"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
          to: "/all-import-approval",
        },
        {
          text: i18n.t("Import Edit"),
        },
      ],
    },
    props:{
      linkForGroup: 'all-import-approval',
    }
  },

  {
    path: "/import-add/:id/:kind",
    name: "import-add",
    component: () =>
      import("@/views/pages/erp/inventory/importedItem/import/form/Add.vue"),
    meta: {
      pageTitle: i18n.t("Import Add"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
        },
        {
          text: i18n.t("Import Lists"),
        },
        {
          text: i18n.t("Import Add"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/import-by-po",
    name: "import-by-po",
    component: () => import("@/views/pages/erp/inventory/importByPO/Main.vue"),
    meta: {
      pageTitle: i18n.t("Import By PO"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
        },
        {
          text: i18n.t("Import By PO"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/import-by-ir",
    name: "import-by-ir",
    component: () => import("@/views/pages/erp/inventory/importByIR/Main.vue"),
    meta: {
      pageTitle: i18n.t("Import By IR"),
      breadcrumb: [
        {
          text: i18n.t("Import Item"),
        },
        {
          text: i18n.t("Import By IR"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/export-by-er",
    name: "export-by-er",
    component: () => import("@/views/pages/erp/inventory/exportByER/Main.vue"),
    meta: {
      pageTitle: i18n.t("Export By ER"),
      breadcrumb: [
        {
          text: i18n.t("Export Item"),
        },
        {
          text: i18n.t("Export By ER"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/export-by-order-pms",
    name: "export-by-order-pms",
    component: () => import("@/views/pages/erp/inventory/exportByPR/Main.vue"),
    meta: {
      pageTitle: i18n.t("Export By PR"),
      breadcrumb: [
        {
          text: i18n.t("Export Item"),
        },
        {
          text: i18n.t("Export By PR"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"export-by-order-pms",
      generateCodeName:"PMS"
    },
  },
  {
    path: "/export-by-order-uis",
    name: "export-by-order-uis",
    component: () => import("@/views/pages/erp/inventory/exportByPR/Main.vue"),
    meta: {
      pageTitle: i18n.t("Export By PR"),
      breadcrumb: [
        {
          text: i18n.t("Export Item"),
        },
        {
          text: i18n.t("Export By PR"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"export-by-order-uis",
      generateCodeName:"UIS"
    },
  },
  {
    path: "/export-by-order-csv",
    name: "export-by-order-csv",
    component: () => import("@/views/pages/erp/inventory/exportByPR/Main.vue"),
    meta: {
      pageTitle: i18n.t("Export By PR"),
      breadcrumb: [
        {
          text: i18n.t("Export Item"),
        },
        {
          text: i18n.t("Export By PR"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"export-by-order-csv",
      generateCodeName:"CSV"
    },
  },
  {
    path: "/person-table-ask",
    name: "person-table-ask",
    component: () =>
    import("@/views/pages/erp/sales/askForProductAdvance/PersonTableAsK.vue"),
    meta: {
      pageTitle: i18n.t("Person Ask Table"),
      breadcrumb: [
        {
          text: i18n.t("Person Ask Table"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-ask"
    },
  },
  {
    path: "/person-table-ask-add",
    name: "person-table-ask-add",
    component: () =>
      import("@/views/pages/erp/sales/askForProductAdvance/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Ask"),
      breadcrumb: [
        {
          text: i18n.t("Ask For Product Advance"),
          to: "/person-table-ask",
        },
        {
          text: i18n.t("Create Ask"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-ask"
    },
  },
  {
    path: "/person-table-ask-detail/:id",
    name: "person-table-ask-detail",
    component: () =>
      import("@/views/pages/erp/sales/askForProductAdvance/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Ask Detail"),
      breadcrumb: [
        {
          text: i18n.t("Ask For Product Advance"),
          to: "/person-table-ask",
        },
        {
          text: i18n.t("Ask Detail"),
        },
      ],
    },
  },
  {
    path: "/person-table-ask-edit/:id",
    name: "person-table-ask-edit",
    component: () =>
      import("@/views/pages/erp/sales/askForProductAdvance/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Ask Edit"),
      breadcrumb: [
        {
          text: i18n.t("Ask For Product Advance"),
          to: "/person-table-ask",
        },
        {
          text: i18n.t("Ask Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-ask"
    },
  },
  {
    path: "/all-table-ask",
    name: "all-table-ask",
    component: () =>
      import("@/views/pages/erp/sales/askForProductAdvance/AllTableAsK.vue"),
    meta: {
      pageTitle: i18n.t("Ask Detail"),
      breadcrumb: [
        {
          text: i18n.t("Ask Detail"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-ask"
    },
  },
  {
    path: "/all-table-ask-add",
    name: "all-table-ask-add",
    component: () =>
      import("@/views/pages/erp/sales/askForProductAdvance/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Ask"),
      breadcrumb: [
        {
          text: i18n.t("Ask For Product Advance"),
          to: "/all-table-ask",
        },
        {
          text: i18n.t("Create Ask"),
          active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-ask"
    },
  },

  {
    path: "/all-table-ask-detail/:id",
    name: "all-table-ask-detail",
    component: () =>
      import("@/views/pages/erp/sales/askForProductAdvance/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Ask Detail"),
      breadcrumb: [
        {
          text: i18n.t("Ask For Product Advance"),
          to: "/all-table-ask",
        },
        {
          text: i18n.t("Ask Detail"),
        },
      ],
    },
  },
  {
    path: "/all-table-ask-edit/:id",
    name: "all-table-ask-edit",
    component: () =>
      import("@/views/pages/erp/sales/askForProductAdvance/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Ask Edit"),
      breadcrumb: [
        {
          text: i18n.t("Ask For Product Advance"),
          to: "/all-table-ask",
        },
        {
          text: i18n.t("Ask Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-ask"
    },
  },
  // ==================================================== Orders PMS ======================================================================
  {
    path: "/person-table-order-pms",
    name: "person-table-order-pms",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Order Detail"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Order Detail"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-pms",
      generateCodeName:"Sale Order PMS"
    },
  },
  {
    path: "/person-table-order-pms-add",
    name: "person-table-order-pms-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Order"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Order Detail"),
          to: "/person-table-order-pms",
        },
        {
        text: i18n.t("Create Order"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-pms",
      generateCodeName:"Sale Order PMS"
    },
  },
  {
    path: "/person-table-order-pms-detail/:id",
    name: "person-table-order-pms-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/person-table-order-pms",
        },
        {
          text: i18n.t("Order Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-pms"
    },
  },
  {
    path: "/person-table-order-pms-edit/:id",
    name: "person-table-order-pms-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/person-table-order-pms",
        },
        {
          text: i18n.t("Order Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-pms"
    },
  },
  {
    path: "/all-table-order-pms",
    name: "all-table-order-pms",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table Order Detail"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Order Detail"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-pms",
      generateCodeName:"Sale Order PMS"
    },
  },
  {
    path: "/all-table-order-pms-add",
    name: "all-table-order-pms-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Order"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Order Detail"),
          to: "/person-table-order-pms",
        },
        {
        text: i18n.t("Create Order"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-pms",
      generateCodeName:"Sale Order PMS"
    },
  },
  {
    path: "/all-table-order-pms-detail/:id",
    name: "all-table-order-pms-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/all-table-order-pms",
        },
        {
          text: i18n.t("Order Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-pms"
    },
  },
  {
    path: "/all-table-order-pms-edit/:id",
    name: "all-table-order-pms-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/all-table-order-pms",
        },
        {
          text: i18n.t("Order Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-pms"
    },
  },

  // ==================================================== Orders UIS ======================================================================
  {
    path: "/person-table-order-uis",
    name: "person-table-order-uis",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Order Detail"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Order Detail"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-uis",
      generateCodeName:"Sale Order UIS"
    },
  },
  {
    path: "/person-table-order-uis-add",
    name: "person-table-order-uis-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Order"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Order Detail"),
          to: "/person-table-order-uis",
        },
        {
        text: i18n.t("Create Order"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-uis",
      generateCodeName:"Sale Order UIS"
    },
  },
  {
    path: "/person-table-order-uis-detail/:id",
    name: "person-table-order-uis-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/person-table-order-uis",
        },
        {
          text: i18n.t("Order Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-uis"
    },
  },
  {
    path: "/person-table-order-uis-edit/:id",
    name: "person-table-order-uis-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/person-table-order-uis",
        },
        {
          text: i18n.t("Order Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-uis"
    },
  },
  {
    path: "/all-table-order-uis",
    name: "all-table-order-uis",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table Order Detail"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Order Detail"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-uis",
      generateCodeName:"Sale Order UIS"
    },
  },
  {
    path: "/all-table-order-uis-add",
    name: "all-table-order-uis-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Order"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Order Detail"),
          to: "/person-table-order-uis",
        },
        {
        text: i18n.t("Create Order"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-uis",
      generateCodeName:"Sale Order UIS"
    },
  },
  {
    path: "/all-table-order-uis-detail/:id",
    name: "all-table-order-uis-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/all-table-order-uis",
        },
        {
          text: i18n.t("Order Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-uis"
    },
  },
  {
    path: "/all-table-order-uis-edit/:id",
    name: "all-table-order-uis-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/all-table-order-uis",
        },
        {
          text: i18n.t("Order Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-uis"
    },
  },

  // ==================================================== Orders CSV ==========================================================================  
  {
    path: "/person-table-order-csv",
    name: "person-table-order-csv",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Order Detail"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Order Detail"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-csv",
      generateCodeName:"Sale Order CSV"
    },
  },
  {
    path: "/person-table-order-csv-add",
    name: "person-table-order-csv-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Order"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Order Detail"),
          to: "/person-table-order-csv",
        },
        {
        text: i18n.t("Create Order"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-csv",
      generateCodeName:"Sale Order CSV"
    },
  },
  {
    path: "/person-table-order-csv-detail/:id",
    name: "person-table-order-csv-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/person-table-order-csv",
        },
        {
          text: i18n.t("Order Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-csv"
    },
  },
  {
    path: "/person-table-order-csv-edit/:id",
    name: "person-table-order-csv-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/person-table-order-csv",
        },
        {
          text: i18n.t("Order Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-csv"
    },
  },
  {
    path: "/all-table-order-csv",
    name: "all-table-order-csv",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table Order Detail"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Order Detail"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-csv",
      generateCodeName:"Sale Order CSV"
    },
  },
  {
    path: "/all-table-order-csv-add",
    name: "all-table-order-csv-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Order"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Order Detail"),
          to: "/person-table-order-csv",
        },
        {
        text: i18n.t("Create Order"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-order-csv",
      generateCodeName:"Sale Order CSV"
    },
  },
  {
    path: "/all-table-order-csv-detail/:id",
    name: "all-table-order-csv-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Order Detail"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/all-table-order-csv",
        },
        {
          text: i18n.t("Order Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-csv"
    },
  },
  {
    path: "/all-table-order-csv-edit/:id",
    name: "all-table-order-csv-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Order Edit"),
      breadcrumb: [
        {
          text: i18n.t("Order"),
          to: "/all-table-order-csv",
        },
        {
          text: i18n.t("Order Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-order-csv"
    },
  },
  // ==================================================== END Orders ==========================================================================
  // ==================================================== Terminated PMS ======================================================================
  {
    path: "/person-table-terminated-pms",
    name: "person-table-terminated-pms",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Terminated PMS"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Terminated PMS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-pms",
      generateCodeName:"Terminated PMS"
    },
  },
  {
    path: "/person-table-terminated-pms-add",
    name: "person-table-terminated-pms-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Terminated"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Terminated PMS"),
          to: "/person-table-terminated-pms",
        },
        {
        text: i18n.t("Create Terminated"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-pms",
      generateCodeName:"Terminated PMS"
    },
  },
  {
    path: "/person-table-terminated-pms-detail/:id",
    name: "person-table-terminated-pms-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Terminated PMS"),
          to: "/person-table-terminated-pms",
        },
        {
          text: i18n.t("Terminated Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-pms"
    },
  },
  {
    path: "/person-table-terminated-pms-edit/:id",
    name: "person-table-terminated-pms-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Terminated PMS"),
          to: "/person-table-terminated-pms",
        },
        {
          text: i18n.t("Terminated Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-pms"
    },
  },
  {
    path: "/all-table-terminated-pms",
    name: "all-table-terminated-pms",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table Terminated PMS"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Terminated PMS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-pms",
      generateCodeName:"Terminated PMS"
    },
  },
  {
    path: "/all-table-terminated-pms-add",
    name: "all-table-terminated-pms-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Terminated"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Terminated PMS"),
          to: "/person-table-terminated-pms",
        },
        {
        text: i18n.t("Create Terminated"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-pms",
      generateCodeName:"Terminated PMS"
    },
  },
  {
    path: "/all-table-terminated-pms-detail/:id",
    name: "all-table-terminated-pms-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table Terminated PMS"),
          to: "/all-table-terminated-pms",
        },
        {
          text: i18n.t("Terminated Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-pms"
    },
  },
  {
    path: "/all-table-terminated-pms-edit/:id",
    name: "all-table-terminated-pms-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Edit"),
      breadcrumb: [
        {
          text: i18n.t("ALL Table Terminated PMS"),
          to: "/all-table-terminated-pms",
        },
        {
          text: i18n.t("Terminated Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-pms"
    },
  },

  // ==================================================== Terminated UIS ======================================================================
  {
    path: "/person-table-terminated-uis",
    name: "person-table-terminated-uis",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Terminated UIS"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Terminated UIS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-uis",
      generateCodeName:"Terminated UIS"
    },
  },
  {
    path: "/person-table-terminated-uis-add",
    name: "person-table-terminated-uis-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Terminated"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Terminated UIS"),
          to: "/person-table-terminated-uis",
        },
        {
        text: i18n.t("Create Terminated"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-uis",
      generateCodeName:"Terminated UIS"
    },
  },
  {
    path: "/person-table-terminated-uis-detail/:id",
    name: "person-table-terminated-uis-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Terminated UIS"),
          to: "/person-table-terminated-uis",
        },
        {
          text: i18n.t("Terminated Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-uis"
    },
  },
  {
    path: "/person-table-terminated-uis-edit/:id",
    name: "person-table-terminated-uis-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Terminated UIS"),
          to: "/person-table-terminated-uis",
        },
        {
          text: i18n.t("Terminated Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-uis"
    },
  },
  {
    path: "/all-table-terminated-uis",
    name: "all-table-terminated-uis",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table Terminated UIS"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Terminated UIS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-uis",
      generateCodeName:"Terminated UIS"
    },
  },
  {
    path: "/all-table-terminated-uis-add",
    name: "all-table-terminated-uis-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Terminated"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Terminated UIS"),
          to: "/person-table-terminated-uis",
        },
        {
        text: i18n.t("Create Terminated"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-uis",
      generateCodeName:"Terminated UIS"
    },
  },
  {
    path: "/all-table-terminated-uis-detail/:id",
    name: "all-table-terminated-uis-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table Terminated UIS"),
          to: "/all-table-terminated-uis",
        },
        {
          text: i18n.t("Terminated Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-uis"
    },
  },
  {
    path: "/all-table-terminated-uis-edit/:id",
    name: "all-table-terminated-uis-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table Terminated UIS"),
          to: "/all-table-terminated-uis",
        },
        {
          text: i18n.t("Terminated Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-uis"
    },
  },

  // ==================================================== Terminated CSV ==========================================================================  
  {
    path: "/person-table-terminated-csv",
    name: "person-table-terminated-csv",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Terminated CSV"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Terminated CSV"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-csv",
      generateCodeName:"Terminated CSV"
    },
  },
  {
    path: "/person-table-terminated-csv-add",
    name: "person-table-terminated-csv-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Terminated"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Terminated CSV"),
          to: "/person-table-terminated-csv",
        },
        {
        text: i18n.t("Create Terminated"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-csv",
      generateCodeName:"Terminated CSV"
    },
  },
  {
    path: "/person-table-terminated-csv-detail/:id",
    name: "person-table-terminated-csv-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Terminated CSV"),
          to: "/person-table-terminated-csv",
        },
        {
          text: i18n.t("Terminated Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-csv"
    },
  },
  {
    path: "/person-table-terminated-csv-edit/:id",
    name: "person-table-terminated-csv-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Terminated CSV"),
          to: "/person-table-terminated-csv",
        },
        {
          text: i18n.t("Terminated Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-csv"
    },
  },
  {
    path: "/all-table-terminated-csv",
    name: "all-table-terminated-csv",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table Terminated CSV"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Terminated CSV"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-csv",
      generateCodeName:"Terminated CSV"
    },
  },
  {
    path: "/all-table-terminated-csv-add",
    name: "all-table-terminated-csv-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Terminated"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Terminated CSV"),
          to: "/person-table-terminated-csv",
        },
        {
        text: i18n.t("Create Terminated"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-terminated-csv",
      generateCodeName:"Terminated CSV"
    },
  },
  {
    path: "/all-table-terminated-csv-detail/:id",
    name: "all-table-terminated-csv-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table Terminated CSV"),
          to: "/all-table-terminated-csv",
        },
        {
          text: i18n.t("Terminated Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-csv"
    },
  },
  {
    path: "/all-table-terminated-csv-edit/:id",
    name: "all-table-terminated-csv-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Terminated Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table Terminated CSV"),
          to: "/all-table-terminated-csv",
        },
        {
          text: i18n.t("Terminated Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-terminated-csv"
    },
  },
  // ==================================================== END Terminated ==========================================================================
  // ==================================================== FOC PMS ======================================================================
  {
    path: "/person-table-foc-pms",
    name: "person-table-foc-pms",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table FOC PMS"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table FOC PMS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-pms",
      generateCodeName:"FOC PMS"
    },
  },
  {
    path: "/person-table-foc-pms-add",
    name: "person-table-foc-pms-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create FOC"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table FOC PMS"),
          to: "/person-table-foc-pms",
        },
        {
        text: i18n.t("Create FOC"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-pms",
      generateCodeName:"FOC PMS"
    },
  },
  {
    path: "/person-table-foc-pms-detail/:id",
    name: "person-table-foc-pms-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("FOC Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table FOC PMS"),
          to: "/person-table-foc-pms",
        },
        {
          text: i18n.t("FOC Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-pms"
    },
  },
  {
    path: "/person-table-foc-pms-edit/:id",
    name: "person-table-foc-pms-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("FOC Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table FOC PMS"),
          to: "/person-table-foc-pms",
        },
        {
          text: i18n.t("FOC Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-pms"
    },
  },
  {
    path: "/all-table-foc-pms",
    name: "all-table-foc-pms",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table FOC PMS"),
      breadcrumb: [        
        {
        text: i18n.t("All Table FOC PMS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-pms",
      generateCodeName:"FOC PMS"
    },
  },
  {
    path: "/all-table-foc-pms-add",
    name: "all-table-foc-pms-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create FOC"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table FOC PMS"),
          to: "/person-table-foc-pms",
        },
        {
        text: i18n.t("Create FOC"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-pms",
      generateCodeName:"FOC PMS"
    },
  },
  {
    path: "/all-table-foc-pms-detail/:id",
    name: "all-table-foc-pms-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("FOC Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table FOC PMS"),
          to: "/all-table-foc-pms",
        },
        {
          text: i18n.t("FOC Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-pms"
    },
  },
  {
    path: "/all-table-foc-pms-edit/:id",
    name: "all-table-foc-pms-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("FOC Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table FOC PMS"),
          to: "/all-table-foc-pms",
        },
        {
          text: i18n.t("FOC Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-pms"
    },
  },

  // ==================================================== FOC UIS ======================================================================
  {
    path: "/person-table-foc-uis",
    name: "person-table-foc-uis",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table FOC UIS"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table FOC UIS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-uis",
      generateCodeName:"FOC UIS"
    },
  },
  {
    path: "/person-table-foc-uis-add",
    name: "person-table-foc-uis-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create FOC"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table FOC UIS"),
          to: "/person-table-foc-uis",
        },
        {
        text: i18n.t("Create FOC"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-uis",
      generateCodeName:"FOC UIS"
    },
  },
  {
    path: "/person-table-foc-uis-detail/:id",
    name: "person-table-foc-uis-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("FOC Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Order UIS"),
          to: "/person-table-foc-uis",
        },
        {
          text: i18n.t("FOC Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-uis"
    },
  },
  {
    path: "/person-table-foc-uis-edit/:id",
    name: "person-table-foc-uis-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("FOC Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Order UIS"),
          to: "/person-table-foc-uis",
        },
        {
          text: i18n.t("FOC Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-uis"
    },
  },
  {
    path: "/all-table-foc-uis",
    name: "all-table-foc-uis",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table FOC UIS"),
      breadcrumb: [        
        {
        text: i18n.t("All Table FOC UIS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-uis",
      generateCodeName:"FOC UIS"
    },
  },
  {
    path: "/all-table-foc-uis-add",
    name: "all-table-foc-uis-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create FOC"),
      breadcrumb: [        
        {
          text: i18n.t("All Table FOC UIS"),
          to: "/person-table-foc-uis",
        },
        {
        text: i18n.t("Create FOC"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-uis",
      generateCodeName:"FOC UIS"
    },
  },
  {
    path: "/all-table-foc-uis-detail/:id",
    name: "all-table-foc-uis-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("FOC Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table FOC UIS"),
          to: "/all-table-foc-uis",
        },
        {
          text: i18n.t("FOC Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-uis"
    },
  },
  {
    path: "/all-table-foc-uis-edit/:id",
    name: "all-table-foc-uis-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("FOC Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table FOC UIS"),
          to: "/all-table-foc-uis",
        },
        {
          text: i18n.t("FOC Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-uis"
    },
  },

  // ==================================================== FOC CSV ==========================================================================  
  {
    path: "/person-table-foc-csv",
    name: "person-table-foc-csv",
    component: () =>
      import("@/views/pages/erp/sales/orders/PersonTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("Person Table FOC CSV"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table FOC CSV"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-csv",
      generateCodeName:"FOC CSV"
    },
  },
  {
    path: "/person-table-foc-csv-add",
    name: "person-table-foc-csv-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create FOC"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table FOC CSV"),
          to: "/person-table-foc-csv",
        },
        {
        text: i18n.t("Create FOC"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-csv",
      generateCodeName:"FOC CSV"
    },
  },
  {
    path: "/person-table-foc-csv-detail/:id",
    name: "person-table-foc-csv-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("FOC Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table FOC CSV"),
          to: "/person-table-foc-csv",
        },
        {
          text: i18n.t("FOC Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-csv"
    },
  },
  {
    path: "/person-table-foc-csv-edit/:id",
    name: "person-table-foc-csv-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("FOC Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table FOC CSV"),
          to: "/person-table-foc-csv",
        },
        {
          text: i18n.t("FOC Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-csv"
    },
  },
  {
    path: "/all-table-foc-csv",
    name: "all-table-foc-csv",
    component: () => import("@/views/pages/erp/sales/orders/AllTableOrder.vue"),
    meta: {
      pageTitle: i18n.t("All Table FOC CSV"),
      breadcrumb: [        
        {
        text: i18n.t("All Table FOC CSV"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-csv",
      generateCodeName:"FOC CSV"
    },
  },
  {
    path: "/all-table-foc-csv-add",
    name: "all-table-foc-csv-add",
    component: () => import("@/views/pages/erp/sales/orders/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create FOC"),
      breadcrumb: [        
        {
          text: i18n.t("All Table FOC CSV"),
          to: "/person-table-foc-csv",
        },
        {
        text: i18n.t("Create FOC"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-foc-csv",
      generateCodeName:"FOC CSV"
    },
  },
  {
    path: "/all-table-foc-csv-detail/:id",
    name: "all-table-foc-csv-detail",
    component: () => import("@/views/pages/erp/sales/orders/Detail.vue"),
    meta: {
      pageTitle: i18n.t("FOC Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table FOC CSV"),
          to: "/all-table-foc-csv",
        },
        {
          text: i18n.t("FOC Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-csv"
    },
  },
  {
    path: "/all-table-foc-csv-edit/:id",
    name: "all-table-foc-csv-edit",
    component: () => import("@/views/pages/erp/sales/orders/Edit.vue"),
    meta: {
      pageTitle: i18n.t("FOC Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table FOC CSV"),
          to: "/all-table-foc-csv",
        },
        {
          text: i18n.t("FOC Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-foc-csv"
    },
  },
  // ==================================================== END FOC ==========================================================================
  // ==================================================== Borrow PMS ======================================================================
  {
    path: "/person-table-borrow-pms",
    name: "person-table-borrow-pms",
    component: () =>
      import("@/views/pages/erp/sales/borrow/PersonTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Borrow PMS"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Borrow PMS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-pms",
      generateCodeName:"Borrow PMS"
    },
  },
  {
    path: "/person-table-borrow-pms-add",
    name: "person-table-borrow-pms-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Borrow PMS"),
          to: "/person-table-borrow-pms",
        },
        {
        text: i18n.t("Create Borrow"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-pms",
      generateCodeName:"Borrow PMS"
    },
  },
  {
    path: "/person-table-borrow-pms-detail/:id",
    name: "person-table-borrow-pms-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Borrow PMS"),
          to: "/person-table-borrow-pms",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-pms"
    },
  },
  {
    path: "/person-table-borrow-pms-edit/:id",
    name: "person-table-borrow-pms-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Borrow PMS"),
          to: "/person-table-borrow-pms",
        },
        {
          text: i18n.t("Borrow Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-pms"
    },
  },
  {
    path: "/all-table-borrow-pms",
    name: "all-table-borrow-pms",
    component: () => import("@/views/pages/erp/sales/borrow/AllTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("All Table Borrow PMS"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Borrow PMS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-pms",
      generateCodeName:"Borrow PMS"
    },
  },
  {
    path: "/all-table-borrow-pms-add",
    name: "all-table-borrow-pms-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [        
        {
          text: i18n.t("All Table Borrow PMS"),
          to: "/person-table-borrow-pms",
        },
        {
        text: i18n.t("Create Borrow"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-pms",
      generateCodeName:"Borrow PMS"
    },
  },
  {
    path: "/all-table-borrow-pms-detail/:id",
    name: "all-table-borrow-pms-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table Borrow PMS"),
          to: "/all-table-borrow-pms",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-pms"
    },
  },
  {
    path: "/all-table-borrow-pms-edit/:id",
    name: "all-table-borrow-pms-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table Borrow PMS"),
          to: "/all-table-borrow-pms",
        },
        {
          text: i18n.t("Borrow Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-pms"
    },
  },

  // ==================================================== Borrow UIS ======================================================================
  {
    path: "/person-table-borrow-uis",
    name: "person-table-borrow-uis",
    component: () =>
      import("@/views/pages/erp/sales/borrow/PersonTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Borrow UIS"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Borrow UIS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-uis",
      generateCodeName:"Borrow UIS"
    },
  },
  {
    path: "/person-table-borrow-uis-add",
    name: "person-table-borrow-uis-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Borrow UIS"),
          to: "/person-table-borrow-uis",
        },
        {
        text: i18n.t("Create Borrow"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-uis",
      generateCodeName:"Borrow UIS"
    },
  },
  {
    path: "/person-table-borrow-uis-detail/:id",
    name: "person-table-borrow-uis-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Borrow UIS"),
          to: "/person-table-borrow-uis",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-uis"
    },
  },
  {
    path: "/person-table-borrow-uis-edit/:id",
    name: "person-table-borrow-uis-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Borrow UIS"),
          to: "/person-table-borrow-uis",
        },
        {
          text: i18n.t("Borrow Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-uis"
    },
  },
  {
    path: "/all-table-borrow-uis",
    name: "all-table-borrow-uis",
    component: () => import("@/views/pages/erp/sales/borrow/AllTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("All Table Borrow UIS"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Borrow UIS"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-uis",
      generateCodeName:"Borrow UIS"
    },
  },
  {
    path: "/all-table-borrow-uis-add",
    name: "all-table-borrow-uis-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [        
        {
          text: i18n.t("All Table Borrow UIS"),
          to: "/person-table-borrow-uis",
        },
        {
        text: i18n.t("Create Borrow"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-uis",
      generateCodeName:"Borrow UIS"
    },
  },
  {
    path: "/all-table-borrow-uis-detail/:id",
    name: "all-table-borrow-uis-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table Borrow UIS"),
          to: "/all-table-borrow-uis",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-uis"
    },
  },
  {
    path: "/all-table-borrow-uis-edit/:id",
    name: "all-table-borrow-uis-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table Borrow UIS"),
          to: "/all-table-borrow-uis",
        },
        {
          text: i18n.t("Borrow Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-uis"
    },
  },

  // ==================================================== Borrow CSV ==========================================================================  
  {
    path: "/person-table-borrow-csv",
    name: "person-table-borrow-csv",
    component: () =>
      import("@/views/pages/erp/sales/borrow/PersonTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("Person Table Borrow CSV"),
      breadcrumb: [        
        {
        text: i18n.t("Person Table Borrow CSV"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-csv",
      generateCodeName:"Borrow CSV"
    },
  },
  {
    path: "/person-table-borrow-csv-add",
    name: "person-table-borrow-csv-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [        
        {
          text: i18n.t("Person Table Borrow CSV"),
          to: "/person-table-borrow-csv",
        },
        {
        text: i18n.t("Create Borrow"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-csv",
      generateCodeName:"Borrow CSV"
    },
  },
  {
    path: "/person-table-borrow-csv-detail/:id",
    name: "person-table-borrow-csv-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Borrow CSV"),
          to: "/person-table-borrow-csv",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-csv"
    },
  },
  {
    path: "/person-table-borrow-csv-edit/:id",
    name: "person-table-borrow-csv-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Edit"),
      breadcrumb: [
        {
          text: i18n.t("Person Table Borrow CSV"),
          to: "/person-table-borrow-csv",
        },
        {
          text: i18n.t("Borrow Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-csv"
    },
  },
  {
    path: "/all-table-borrow-csv",
    name: "all-table-borrow-csv",
    component: () => import("@/views/pages/erp/sales/borrow/AllTableBorrow.vue"),
    meta: {
      pageTitle: i18n.t("All Table Borrow CSV"),
      breadcrumb: [        
        {
        text: i18n.t("All Table Borrow CSV"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-csv",
      generateCodeName:"Borrow CSV"
    },
  },
  {
    path: "/all-table-borrow-csv-add",
    name: "all-table-borrow-csv-add",
    component: () => import("@/views/pages/erp/sales/borrow/Add.vue"),
    meta: {
      pageTitle: i18n.t("Create Borrow"),
      breadcrumb: [        
        {
          text: i18n.t("All Table Borrow CSV"),
          to: "/person-table-borrow-csv",
        },
        {
        text: i18n.t("Create Borrow"),
        active: true,
        },
      ],
    },
    props:{
      linkForGroup:"person-table-borrow-csv",
      generateCodeName:"Borrow CSV"
    },
  },
  {
    path: "/all-table-borrow-csv-detail/:id",
    name: "all-table-borrow-csv-detail",
    component: () => import("@/views/pages/erp/sales/borrow/Detail.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Detail"),
      breadcrumb: [
        {
          text: i18n.t("All Table Borrow CSV"),
          to: "/all-table-borrow-csv",
        },
        {
          text: i18n.t("Borrow Detail"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-csv"
    },
  },
  {
    path: "/all-table-borrow-csv-edit/:id",
    name: "all-table-borrow-csv-edit",
    component: () => import("@/views/pages/erp/sales/borrow/Edit.vue"),
    meta: {
      pageTitle: i18n.t("Borrow Edit"),
      breadcrumb: [
        {
          text: i18n.t("All Table Borrow CSV"),
          to: "/all-table-borrow-csv",
        },
        {
          text: i18n.t("Borrow Edit"),
        },
      ],
    },
    props:{
      linkForGroup:"all-table-borrow-csv"
    },
  },
  // ==================================================== END Borrow ==========================================================================
  {
    path: "/return-pos",
    name: "return-pos",
    component: () =>
      import("@/views/pages/erp/inventory/returnItem/POS/Main.vue"),
    meta: {
      pageTitle: i18n.t("Return POS"),
      breadcrumb: [
        {
          text: i18n.t("Return Item"),
        },
        {
          text: i18n.t("Return POS"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/return-confirm",
    name: "return-confirm",
    component: () =>
      import("@/views/pages/erp/inventory/returnItem/POS/ConfirmReturn.vue"),
    meta: {
      pageTitle: i18n.t("Return Confirm"),
      breadcrumb: [
        {
          text: i18n.t("Return Item"),
          to: "/return-pos",
        },
        {
          text: i18n.t("Return Confirm"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/my-notifications",
    name: "my-notifications",
    component: () =>
      import("@/views/pages/erp/notification/myNotification.vue"),
    meta: {
      pageTitle: i18n.t("My Notification"),
      breadcrumb: [
        {
          text: i18n.t("Timeline"),
          active: true,
        },
      ],
    },
  },
];
