// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { menuPermissionPass } from "@core/app-config/menu_permission";

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const isUserGetPermission = (menu) => {
  //return true // for test
  if(menuPermissionPass.includes(menu)){
    return true
  }
  const role_permission = getRolePermission()
  if (role_permission) {
    const { menu_permission } = role_permission
    
    if (menu_permission && menu_permission.length > 0) {
      return menu_permission.map(v => v.route).find(v => menu.includes(v)) ? true : false
    }
    return false
  }
  return false
}

export const getRolePermission = () => JSON.parse(localStorage.getItem('rolePermission'))

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return 'setting-profile'
  return { name: 'setting-profile' }
}
