import i18n from '@/libs/i18n'

export default [
  {
    header: i18n.t('Management'),
  },
  {
    title: i18n.t('Users Management'),
    route: 'user-lists',
    icon: 'PlusSquareIcon',
  },
  {
    title: i18n.t('Role App'),
    route: 'role',
    icon: 'UserIcon',
  },
  {
    title: i18n.t('Unit Master'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Unit Master Table'),
        route: 'unit',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Site Master'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Site Master Table'),
        route: 'site',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Item Position Master'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Item Position Master Table'),
        route: 'item-position',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Category'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Category List'),
        route: 'category-lists',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('ProductCodeMaster'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Main Product List'),
        route: 'product-code-main-lists',
        icon: '',
      },
      {
        title: i18n.t('Department List'),
        route: 'product-code-department-lists',
        icon: '',
      },
      {
        title: i18n.t('Product Group List'),
        route: 'product-code-group-lists',
        icon: '',
      },
      {
        title: i18n.t('Product Type List'),
        route: 'product-code-type-lists',
        icon: '',
      },
      {
        title: i18n.t('Brand List'),
        route: 'product-code-brand-lists',
        icon: '',
      },
      {
        title: i18n.t('Model List'),
        route: 'product-code-model-lists',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Position'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Position List'),
        route: 'position-lists',
        icon: 'KeyIcon',
      },
    ],
  },
  {
    title: i18n.t('UserArea'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('UserArea List'),
        route: 'userarea',
        icon: 'KeyIcon',
      },
    ],
  },
]
