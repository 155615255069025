import i18n from "@/libs/i18n";

export default [
  {
    header: i18n.t("Customer"),
  },
  {
    title: i18n.t("Customer Registation"),
    icon: "FilePlusIcon",
    children: [
      {
        title: i18n.t("Customer Registation All"),
        route: "customer-All",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Goverment"),
        route: "customer-goverment",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Private"),
        route: "customer-private",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Clinic"),
        route: "customer-clinic",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Donator"),
        route: "customer-donator",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Sub-Dealer"),
        route: "customer-sub-dealer",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Others"),
        route: "customer-others",
        icon: "FilePlusIcon",
      },
    ],
  },
  {

    header: i18n.t("Supplier"),
  },
  {
    title: i18n.t("Supplier Registation"),
    icon: "FileIcon",
    children: [
      {
        title: i18n.t("Supplier Registation All"),
        route: "vendor",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Oversea"),
        route: "vendor-oversea",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Domestic"),
        route: "vendor-domestic",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Transportation"),
        route: "vendor-transportation",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Shipping Forwarder"),
        route: "vendor-shipping-forwarder",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Account"),
        route: "vendor-account",
        icon: "FilePlusIcon",
      },
    ]
  },    
  {
    header: i18n.t("Purchase"),
  },
  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "purchase-dashboard",
  },
  {
    title: i18n.t("Purchase Request"),
    icon: "EditIcon",
    children: [
      {
        title: i18n.t("Person Purchase Request Table"),
        route: "purchase-request-domestic",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Request"),
        route: "all-purchase-request-domestic",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Purchase Request Out"),
    icon: "EditIcon",
    children: [
      {
        title: i18n.t("Person Purchase Request Table Out"),
        route: "purchase-request-oversea",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Request Out"),
        route: "all-purchase-request-oversea",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Purchase Order"),
    icon: "FileTextIcon",
    children: [
      // {
      //   title: i18n.t('Person Purchase Order Table'),
      //   route: 'person-purchase-order',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('All Purchase Order Table'),
      //   route: 'all-purchase-order',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('Person Purchase Order Approval Table'),
      //   route: 'person-purchase-order-approval',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('All Purchase Order Approval Table'),
      //   route: 'all-purchase-order-approval',
      //   icon: '',
      // },
      {
        title: i18n.t("Person Purchase Order Table"),
        route: "purchase-order-domestic",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Order"),
        route: "all-purchase-order-domestic",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Purchase Order Out"),
    icon: "FileTextIcon",
    children: [
      // {
      //   title: i18n.t('Person Purchase Order Table Out'),
      //   route: 'person-purchase-order-out',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('All Purchase Order Table Out'),
      //   route: 'all-purchase-order-out',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('Person Purchase Order Approval Table Out'),
      //   route: 'person-purchase-order-approval-out',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('All Purchase Order Approval Table Out'),
      //   route: 'all-purchase-order-approval-out',
      //   icon: '',
      // },
      {
        title: i18n.t("Person Purchase Order Table Out"),
        route: "purchase-order-oversea",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Order"),
        route: "all-purchase-order-oversea",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Compare"),
    icon: "RefreshCcwIcon",
    children: [
      {
        title: i18n.t("Person Compare Table"),
        route: "purchase-compare",
        icon: "",
      },
      {
        title: i18n.t("All Compare Table"),
        route: "all-purchase-compare",
        icon: "",
      },
      {
        title: i18n.t("Summary"),
        route: "summary-lists",
        icon: "",
      },
    ],
  },
  {
    header: i18n.t("Inventory"),
  },
  {
    title: i18n.t("Dashboard"),
    icon: "TrendingUpIcon",
    route: "inventory-dashboard",
  },
  {
    title: i18n.t("Product Master"),
    icon: "HardDriveIcon",
    route: "product-master",
  },
  {
    title: i18n.t("Add Cost Item"),
    icon: "DollarSignIcon",
    route: "add-cost-item",
  },
  {
    title: i18n.t("Show Cost Item"),
    icon: "FileTextIcon",
    route: "cost",
  },
  {
    title: i18n.t("Tracking Item"),
    icon: "SearchIcon",
    children: [
      {
        title: i18n.t("Search Item"),
        route: "item",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Import Item"),
    icon: "DownloadIcon",
    children: [
      // {
      //   title: i18n.t('Person Import Table'),
      //   route: 'person-import',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('All Import Table'),
      //   route: 'all-import',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('Person Import Approval Table'),
      //   route: 'person-import-approval',
      //   icon: '',
      // },
      // {
      //   title: i18n.t('All Import Approval Table'),
      //   route: 'all-import-approval',
      //   icon: '',
      // },
      {
        title: i18n.t("Person Import Item"),
        route: "person-import-approval",
        icon: "",
      },
      {
        title: i18n.t("All Import Item"),
        route: "all-import-approval",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Export Item"),
    icon: "TruckIcon",
    children: [
      {
        //   title: i18n.t('Person Export Table'),
        //   route: 'person-export',
        //   icon: '',
        // },
        // {
        //   title: i18n.t('All Export Table'),
        //   route: 'all-export',
        //   icon: '',
        // },
        // {
        //   title: i18n.t('Person Export Approval Table'),
        //   route: 'person-export-approval',
        //   icon: '',
        // },
        // {
        //   title: i18n.t('All Export Approval Table'),
        //   route: 'all-export-approval',
        //   icon: '',
        // },
        // {
        title: i18n.t("Person Export Item"),
        route: "person-table-export",
        icon: "",
      },
      {
        title: i18n.t("All Export Item"),
        route: "all-table-export",
        icon: "",
      },
    ],
  },

  {
    title: i18n.t("Stock Item"),
    icon: "PackageIcon",
    children: [
      {
        title: i18n.t("Stock Lists"),
        route: "stock-lists",
        icon: "",
      },
      {
        title: i18n.t("Import By PO"),
        route: "import-by-po",
        icon: "",
      },
      {
        title: i18n.t("Import By IR"),
        route: "import-by-ir",
        icon: "",
      },
      // {
      //   title: i18n.t("Export By ER"),
      //   route: "export-by-er",
      //   icon: "",
      // },
      {
        title: i18n.t("Export By PMS"),
        route: "export-by-order-pms",
        icon: "",
      },
      {
        title: i18n.t("Export By UIS"),
        route: "export-by-order-uis",
        icon: "",
      },
      {
        title: i18n.t("Export By CSV"),
        route: "export-by-order-csv",
        icon: "",
      },
      {
        title: i18n.t("Export Log"),
        icon: "",
        route: "export-log",
      },
      {
        title: i18n.t("Export POS"),
        route: "export-pos",
        icon: "",
      },

      {
        title: i18n.t("Item Exports"),
        icon: "",
        route: "inven-stock",
      },
      {
        title: i18n.t("Return POS"),
        route: "return-pos",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Packaging"),
    icon: "PackageIcon",
    children: [      
      {
        title: i18n.t("Person Packaging"),
        route: "person-table-packaging",
      },
      {
        title: i18n.t("All Packaging"),
        route: "all-table-packaging",
      },
    ]
  },
  {
    header: i18n.t("Job"),
  },
  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "project-dashboard",
  },
  {
    title: i18n.t("Job"),
    icon: "FileIcon",
    children: [
      {
        title: i18n.t("Project-PMS"),
        route: "project-pms",
        icon: "",
      },
      {
        title: i18n.t("Project-UIS"),
        route: "project-uis",
        icon: "",
      },
      {
        title: i18n.t("Project-CSV"),
        route: "project-csv",
        icon: "",
      },
    ],
  },

  {
    header: i18n.t("Approve Job"),
  },
  {
    title: i18n.t("Approve Job-PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Approve Job-PMS"),
        route: "Person-Table-Approve-PMS",
        icon: "",
      },
      {
        title: i18n.t("All Table Approve Job-PMS"),
        route: "All-Table-Approve-PMS",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Approve Job-UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Approve Job-UIS"),
        route: "Person-Table-Approve-UIS",
        icon: "",
      },
      {
        title: i18n.t("All Table Approve Job-UIS"),
        route: "All-Table-Approve-UIS",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Approve Job-CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Approve Job-CSV"),
        route: "Person-Table-Approve-CSV",
        icon: "",
      },
      {
        title: i18n.t("All Table Approve Job-CSV"),
        route: "All-Table-Approve-CSV",
        icon: "",
      },
    ],
  },


  {
    header: i18n.t("admin"),
  },

  {
    title: i18n.t("Quotation PMS Admin"),
    // route: "admin-table-quotation-pms",
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation PMS"),
        route: "admin-person-table-quotation-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation PMS"),
        route: "admin-table-quotation-pms",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Quotation UIS Admin"),
    // route: "admin-table-quotation-uis",
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation UIS"),
        route: "admin-person-table-quotation-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation UIS"),
        route: "admin-table-quotation-uis",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Quotation CSV Admin"),
    route: "admin-table-quotation-csv",
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation CSV"),
        route: "admin-person-table-quotation-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation CSV"),
        route: "admin-table-quotation-csv",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Close Job PMS"),
    route: "admin-table-close-job-pms",
    icon: "FileTextIcon",
  },
  {
    title: i18n.t("Close Job UIS"),
    route: "admin-table-close-job-uis",
    icon: "FileTextIcon",
  },
  {
    title: i18n.t("Close Job CSV"),
    route: "admin-table-close-job-csv",
    icon: "FileTextIcon",
  },
  {
    header: i18n.t("Sales"),
  },

  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "sale-dashboard",
  },
  {
    title: i18n.t("Close Job PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Close Job PMS"),
        route: "person-table-close-job-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Close Job PMS"),
        route: "all-table-close-job-pms",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Close Job UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Close Job UIS"),
        route: "person-table-close-job-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Close Job UIS"),
        route: "all-table-close-job-uis",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Close Job CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Close Job CSV"),
        route: "person-table-close-job-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Close Job CSV"),
        route: "all-table-close-job-csv",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Quotation PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation PMS"),
        route: "person-table-quotation-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation PMS"),
        route: "all-table-quotation-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Quotation UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation UIS"),
        route: "person-table-quotation-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation UIS"),
        route: "all-table-quotation-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Quotation CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation CSV"),
        route: "person-table-quotation-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation CSV"),
        route: "all-table-quotation-csv",
        icon: "",
      },
    ],
  },
  // {
  //   title: i18n.t("Borrow"),
  //   icon: "ShareIcon",
  //   children: [
  //     // {
  //     //   title: i18n.t('Person Borrow Table'),
  //     //   route: 'person-borrow',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('All Borrow Table'),
  //     //   route: 'all-borrow',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('Person Borrow Approval Table'),
  //     //   route: 'person-borrow-approval',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('All Borrow Approval Table'),
  //     //   route: 'all-borrow-approval',
  //     //   icon: '',
  //     // },
  //     {
  //       title: i18n.t("Person Table Borrow"),
  //       route: "person-table-borrow",
  //       icon: "",
  //     },
  //     {
  //       title: i18n.t("All Table Borrow"),
  //       route: "all-borrow",
  //       icon: "",
  //     },
  //   ],
  // },
  // {
  //   title: i18n.t("Ask For Product Advance"),
  //   icon: "ShareIcon",
  //   children: [
  //     // {
  //     //   title: i18n.t('Person Ask Table'),
  //     //   route: 'person-table-ask',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('All Ask Table'),
  //     //   route: 'all-ask',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('Person Ask Approval Table'),
  //     //   route: 'person-table-ask-approval',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('All Ask Approval Table'),
  //     //   route: 'all-ask-approval',
  //     //   icon: '',
  //     // },
  //     {
  //       title: i18n.t("Person Ask Table For Product Advance"),
  //       route: "person-table-ask",
  //       icon: "",
  //     },
  //     {
  //       title: i18n.t("Ask Table For Product Advance"),
  //       route: "all-table-ask",
  //       icon: "",
  //     },
  //   ],
  // },
  {
    title: i18n.t("Orders PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Orders"),
        route: "person-table-order-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Orders"),
        route: "all-table-order-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Orders UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Orders"),
        route: "person-table-order-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Orders"),
        route: "all-table-order-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Orders CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Orders"),
        route: "person-table-order-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Orders"),
        route: "all-table-order-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Terminated PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Terminated PMS"),
        route: "person-table-terminated-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Terminated PMS"),
        route: "all-table-terminated-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Terminated UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Terminated UIS"),
        route: "person-table-terminated-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Terminated UIS"),
        route: "all-table-terminated-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Terminated CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Terminated CSV"),
        route: "person-table-terminated-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Terminated CSV"),
        route: "all-table-terminated-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("FOC PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table FOC PMS"),
        route: "person-table-foc-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table FOC PMS"),
        route: "all-table-foc-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("FOC UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table FOC UIS"),
        route: "person-table-foc-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table FOC UIS"),
        route: "all-table-foc-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("FOC CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table FOC CSV"),
        route: "person-table-foc-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table FOC CSV"),
        route: "all-table-foc-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Borrow PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Borrow"),
        route: "person-table-borrow-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Borrow"),
        route: "all-table-borrow-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Borrow UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Borrow"),
        route: "person-table-borrow-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Borrow"),
        route: "all-table-borrow-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Borrow CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Borrow"),
        route: "person-table-borrow-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Borrow"),
        route: "all-table-borrow-csv",
        icon: "",
      },
    ],
  },
  {
    header: i18n.t("Accounting"),
  },
  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "accounting-dashboard",
  },
  // {
  //   title: i18n.t("Invoice"),
  //   icon: "FileTextIcon",
  //   route: "invoice-form",
  //   children: [
  //     // {
  //     //   title: i18n.t('Person Invoice Table'),
  //     //   route: 'person-invoice',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('All Invoice Table'),
  //     //   route: 'all-invoice',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('Person Invoice Approval Table'),
  //     //   route: 'person-invoice-approval',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t('All Invoice Approval Table'),
  //     //   route: 'all-invoice-approval',
  //     //   icon: '',
  //     // },
  //     // {
  //     //   title: i18n.t("Person Table Invoice"),
  //     //   route: "person-table-invoice",
  //     //   icon: "",
  //     // },
  //     // {
  //     //   title: i18n.t("All Table Invoice"),
  //     //   route: "all-table-invoice",
  //     //   icon: "",
  //     // },
  //     {
  //       title: i18n.t("All Table Invoice PMS"),
  //       route: "all-table-invoice-pms",
  //       icon: "",
  //     },
  //     {
  //       title: i18n.t("All Table Invoice UIS"),
  //       route: "all-table-invoice-uis",
  //       icon: "",
  //     },
  //     {
  //       title: i18n.t("All Table Invoice CSV"),
  //       route: "all-table-invoice-csv",
  //       icon: "",
  //     },
  //   ],
  // },
  {
    title: i18n.t("Invoice PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Invoice PMS"),
        route: "person-table-invoice-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Invoice PMS"),
        route: "all-table-invoice-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Invoice UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Invoice UIS"),
        route: "person-table-invoice-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Invoice UIS"),
        route: "all-table-invoice-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Invoice CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Invoice CSV"),
        route: "person-table-invoice-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Invoice CSV"),
        route: "all-table-invoice-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Billing PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Billing PMS"),
        route: "person-table-billing-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Billing PMS"),
        route: "all-table-billing-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Billing UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Billing UIS"),
        route: "person-table-billing-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Billing UIS"),
        route: "all-table-billing-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Billing CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Billing CSV"),
        route: "person-table-billing-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Billing CSV"),
        route: "all-table-billing-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Receipt PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Receipt PMS"),
        route: "person-table-receipt-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Receipt PMS"),
        route: "all-table-receipt-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Receipt UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Receipt UIS"),
        route: "person-table-receipt-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Receipt UIS"),
        route: "all-table-receipt-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Receipt CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Receipt CSV"),
        route: "person-table-receipt-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Receipt CSV"),
        route: "all-table-receipt-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Cash Invoice PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Cash Invoice PMS"),
        route: "person-table-cash-invoice-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Cash Invoice PMS"),
        route: "all-table-cash-invoice-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Cash Invoice UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Cash Invoice UIS"),
        route: "person-table-cash-invoice-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Cash Invoice UIS"),
        route: "all-table-cash-invoice-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Cash Invoice CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Cash Invoice CSV"),
        route: "person-table-cash-invoice-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Cash Invoice CSV"),
        route: "all-table-cash-invoice-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Cost"),
    icon: "DollarSignIcon",
    children: [
      {
        title: i18n.t("Cost Table"),
        route: "cost-table",
        icon: "",
      },
      {
        title: i18n.t("Cost Calendar"),
        route: "cost-calendar",
        icon: "",
      },
    ],
  },
  // {
  //   title: "Dashborad",
  //   icon: "TrendingUpIcon",
  //   route: "inventory-dashboard",
  // },
  {
    header: i18n.t("Setting"),
  },
  {
    title: i18n.t("Exchange Rate"),
    icon: "DollarSignIcon",
    route: "setting-exchange-rate",
  },
  {
    title: i18n.t("Generate Code"),
    icon: "HashIcon",
    route: "setting-generate-code",
  },
  {
    title: i18n.t("User Approve"),
    icon: "HashIcon",
    route: "setting-user-approve",
  },
  {
    title: i18n.t("Payment"),
    icon: "DollarSignIcon",
    route: "setting-payment",
  },
  {
    title: i18n.t("Setting App"),
    icon: "SettingsIcon",
    route: "setting-app",
  },
];
